import React, { useEffect } from "react";
import "./App.css";
import "../src/Assets/Css/Custome.css";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./Front/Home";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import ProductList from "./Shopping/ProductList";
import WishList from "./Shopping/Wishlist";
import Cart from "./Shopping/Cart";
import CheckOut from "./Shopping/Checkout";
import "react-image-gallery/styles/css/image-gallery.css";
import ProductDetails from "./Shopping/ProductDetails";
import Login from "./Static/Login";
import Register from "./Static/Register";
import About from "./Static/About";
import MyAccount from "./Static/Myaccount";
import GotoTop from "./Static/GotoTop";
import FAQ from "./Static/Faq";
import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { getData } from "./Helpers/request";
import Success from "./Shopping/Success";
import ProductCategory from "./Shopping/productCategory";
import { useSelector } from "react-redux";
import Invoice from "./Static/Invoice";
import Services from "./Static/Services";
import Contact1 from "./Static/Contact1";
// import Contact from "./Static/Contact"

function App() {
  let auth = useSelector((state) => state.persistedReducer.user.userData);

  const [menu, setMenu] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getMenu();
  }, []);
  const getMenu = async () => {
    setLoading(true);
    const response = await getData("/common/menu");
    if (response.data.status == 1) {
      setMenu(response.data.data);
      setLoading(false);
    } else {
      toast.error(response.message, { autoClose: 3000 });
    }
  };




  function RequireAuth({ children }) {
    let auth = useSelector((state) => state.persistedReducer.user.userData);
    let location = useLocation();

    if (!auth.isLogin) {
      return <Navigate to="/login" state={{ from: location }} />;
    }

    return children;
  }

  return (
    <>
      <Router>

        <Header menuData={menu} />
        <Routes>

          <Route path="/" element={<Home />}></Route>

          {auth.isLogin ?
            <><Route path="/login" element={<Navigate to="/" />}></Route>
              <Route path="/register" element={<Navigate to="/" />}></Route></> :
            <> <Route path="/register" element={<Register />}></Route>
              <Route path="/login" element={<Login />}></Route></>
          }
          <Route path="/myaccount" element={<RequireAuth><MyAccount /></RequireAuth>}></Route>
          <Route path="/product-category/:slug" element={<RequireAuth><ProductCategory /></RequireAuth>}></Route>
          <Route path="/productlist/:slug" element={<RequireAuth><ProductList /></RequireAuth>}></Route>
          <Route path="/search/:search" element={<RequireAuth><ProductList /></RequireAuth>}></Route>
          <Route path="/product-detail/:slug" element={<RequireAuth><ProductDetails /></RequireAuth>}></Route>
          <Route path="/wishlist" element={<RequireAuth><WishList /></RequireAuth>}></Route>
          <Route path="/cart" element={<RequireAuth><Cart /></RequireAuth>} ></Route>
          <Route path="/invoice/:id/" element={<RequireAuth><Invoice /></RequireAuth>} />
          <Route path="/contact-us" element={<Contact1 />}></Route>
          <Route path="/about-us" element={<About />}></Route>
          <Route path="/services" element={<Services />}></Route>
          <Route path="/faq" element={<FAQ />}></Route>
          <Route path="*" element={<Navigate to="/"></Navigate>}></Route>
          {/* <Route
              path="/checkout"
              element={
                <RequireAuth>
                  <CheckOut />
                </RequireAuth>
              }
            ></Route> */}
          {/* <Route
              path="/success/:order_id/:order_no/:txn_id"
              element={<Success />}
            ></Route> */}
        </Routes>
        <Footer />
        <GotoTop />
      </Router>
    </>
  );
}

export default App;
