import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import '../Assets/Css/invoice.css'
import InvoiceImage from '../Assets/Images/new-invoice.png'
import logoImage from '../Assets/Images/logo.svg'
import { base_url, postHeader, user } from '../Helpers/request';
import axios from 'axios';
import { toast } from 'react-toastify';
import {  useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { cartCountvalue } from '../Reducer/cartReducer';
import { whishlistlength } from '../Reducer/wishlistReducer';

const Invoice = (props) => {
    const pageID= useParams();
    const [orderdata, setOrderdata] = useState([]);
    const dispatch = useDispatch()
    useEffect(() => {
        // document.getElementById("rcs_header_seaction").style.display = "none";
        // document.getElementById("rcs_footer").style.display = "none";
        // document.getElementById("rcs_footer_bottom").style.display = "none";
        // document.getElementById("podium-website-widget").style.display = "none";
        // document.getElementById("podium-bubble").style.display = "none";
        orderlist();
    }, [])
    const orderlist = () => {
        const data = { 

            id: pageID?.id,
            user_id: user.user_id ? user.user_id : "",
            token: user.isLogin ? user.token : "",
        }


        axios.post(base_url + '/order/enquiry_detail', data, {
            headers: postHeader
        })
            .then(response => {
                if (response.data.status == 1) {
                    setOrderdata(response.data.data)
                    toast.success(response.data.message, { autoClose: 3000 });
                } else if (response.data.status == 2) {
                    localStorage.removeItem('bw-user')
                    dispatch(whishlistlength(0))
                    dispatch(cartCountvalue(0))
                    localStorage.removeItem("bw-session-id");
                    // history.push("/")
                    window.location.reload(true);
                } else {
                    toast.error(response.data.message, { autoClose: 3000 });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }



    return (
        <>
            <div className="rcs_myaccount">
                <section className="theme-invoice-1 section-b-space">
                    <Container>
                        <Row className="m-auto w-100 justify-content-center">
                            <Col sm={12} md={12} xl={8} lg={12}>
                                <div className="invoice-wrapper">
                                    <div className="invoice-header">
                                        <div className="upper-icon">
                                            <Image
                                                src={InvoiceImage}
                                                alt="Invoice"
                                            />
                                        </div>
                                        <div className="row header-content">
                                            <div className="col-sm-6">
                                                <Image
                                                    src={logoImage}
                                                    alt=" Royal chicago"
                                                    style={{ width: "250px" }}
                                                />
                                                <div className="mt-md-4 mt-3">
                                                    <h4 className="mb-0"> royalchicago@gmail.com</h4>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 text-sm-end mt-md-0 mt-4">
                                                <h2>invoice</h2>
                                                <div className="mt-md-4 mt-3">
                                                    <h4 className="mb-2"> 3301 W 47th Pl</h4>
                                                    <h4 className="mb-0"> Chicago, IL 60632</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="detail-bottom">
                                            <ul>
                                                <li>
                                                    <strong>issue date :</strong>
                                                    <h4>{orderdata?.[0]?.created_at}</h4>
                                                </li>
                                                <li>
                                                    <strong>invoice no :</strong>
                                                    <h4>{orderdata?.[0]?.enquiry_id}</h4>
                                                </li>
                                                <li>
                                                    <strong>email :</strong>
                                                    <h4>{orderdata?.[0]?.user_email}</h4>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="invoice-body table-responsive-md">
                                        <table className="table table-borderless mb-0">
                                            <thead >
                                                <tr>
                                                    <th scope="col"><strong>#</strong></th>
                                                    <th scope="col"><strong>Image</strong></th>
                                                    <th scope="col"><strong>Name</strong></th>
                                                    <th scope="col"><strong>Price</strong></th>
                                                    <th scope="col"><strong>Quantity</strong></th>
                                                    <th scope="col"><strong>Total</strong></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {orderdata?.map((val,index) =>
                                                    <tr>
                                                        <th scope="row">{val?.product_id}</th>
                                                        <td><Image src={val?.image} width="50px" height="50px"></Image></td>
                                                        <td>{val?.name}</td>
                                                        <td>${val?.price}</td>
                                                        <td>{val?.quantity}</td>
                                                        <td>${val?.price}</td>
                                                    </tr>
                                                  
                                                )}
                                            </tbody>    
                                            <tfoot className='border-top border-bottom'>
                                                <tr>
                                                    <td />
                                                    <td className="font-bold text-dark" colSpan={4}>
                                                        <strong>TOTAL</strong>
                                                    </td>
                                                    <td className="font-bold text-theme text-right"> ${orderdata?.[0]?.total_amount} </td>
                                                </tr>
                                                         
                                            </tfoot>
                                        </table>
                                    </div>
                                    {/* <Row className="rcs_address_details rcs_instruction_details mb-5">
                                        <Col xs={12}>
                                            <ul className='rcs_instruction_content'>
                                               {orderdata?.order?.gift_message?.length ? <li className='mb-2'><strong>Gift Options : {orderdata?.order?.gift_message}</strong></li>:""}
                                               {orderdata?.order?.instructions?.length ?<li className='mb-2'><strong>Special Instructions : {orderdata?.order?.instructions}</strong></li>:""}
                                            </ul>
                                        </Col>
                                    </Row> */}
                                    
                                    {/* <Row className="invoice-footer">
                                        <Col sm={6}>
                                        </Col>
                                        <Col sm={6}>
                                            <div className="buttons">
                                                <Button className="rcs_print" onClick={() => window.print()}> <PrintIcon /> Print</Button>
                                            </div>
                                        </Col>
                                    </Row> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
        </>
    )
}
export default Invoice;
