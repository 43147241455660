import React, { useEffect } from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../Assets/Css/Static.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { BsArrowRight } from "react-icons/bs";
import { getData} from '../Helpers/request';
import { useHistory } from 'react-router';

const FAQ = () => {
   
    const [faqdata, setFaqdata] = React.useState([]);
    useEffect(()=>{
       getFaq()
    },[])
    const getFaq = async () =>{
        const res = await getData('/common/faq')
           
                if (res.data.status == 1) {
                    setFaqdata(res.data.data);
                } else {
                    console.log(res.message);
                }
    }

    return (
        <>
            <div className='rt_productlist_section_bg'>
                <Container className='rt_custome_container'>
                    <Row className='w-100 m-auto'>
                        <Col>
                            <div className='rt_productlist_banner_content'>
                                <h1>FAQ's</h1>
                                <ul>
                                    <li><NavLink to='/'>Home</NavLink></li>
                                    <li><BsArrowRight /></li>
                                    <li><NavLink to='#'>FAQ's</NavLink></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="rt_ringsetting_section mt-3 d-none">
                <Container>
                    <Row>
                        <Col sm={12}>
                            <div className="rt_privacy_content rt_mobile_menu mt-3 mb-5 w-100">
                                <div className='rt_faq_accordion mt-5 mb-5'>
                                {faqdata.map(res=>
                                <Accordion key={res.id}>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    >
                                        <Typography>{res.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>{res.value}</Typography>
                                    </AccordionDetails>
                                </Accordion>)}
                                {/* <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel2a-content"
                                    id="panel2a-header"
                                    >
                                        <Typography>What if I don't know my ring size?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>If the information that you are looking for about an item is not included in the description on our website, you can submit a request through email or on the phone. We will do our best to accommodate.</Typography>
                                    </AccordionDetails>
                                </Accordion> */}
                                {/* <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel3a-content"
                                    id="panel3a-header"
                                    >
                                        <Typography>I did not receive my order confirmation email. Can you resend it to me?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>We have an easy-to-use digital ring size chart that you can print out at home to measure your fingers.</Typography>
                                    </AccordionDetails>
                                </Accordion> */}
                                {/* <Accordion>
                                    <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel4a-content"
                                    id="panel4a-header"
                                    >
                                        <Typography>I did not receive my order confirmation email. Can you resend it to me?</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>An order confirmation email is system generated and automatically sent out once your order is approved. If you did not receive your email, please contact customer service and they will forward a copy</Typography>
                                    </AccordionDetails>
                                </Accordion> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default FAQ;