import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import "../Assets/Css/Cart.css";
import { BsArrowRight } from "react-icons/bs";
import productImage from "../Assets/Images/productList/product.png";
import { BsStarFill } from "react-icons/bs";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDispatch, useSelector } from "react-redux";
import {
  cartAddquantity,
  cartCountvalue,
  cartData,
  cartDetails,
  cartLessquantity,
} from "../Reducer/cartReducer";
import { postData } from "../Helpers/request";
import { cartListcount, checkoutData } from "../Reducer/productReducers";
import Nodata from "../../src/Assets/Images/nodata.jpg";
import { Skeleton } from "@mui/material";
import { isTablet } from "react-device-detect";
import swal from "sweetalert";

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.persistedReducer.user?.userData);
  const icon = useSelector(
    (state) => state.persistedReducer.home?.currencyIcon
  );
  const sessionid = useSelector(
    (state) => state.persistedReducer.home?.sessionId
  );
  const cartlist = useSelector(
    (state) => state.persistedReducer.cart?.cartData
  );
  const cartitems = useSelector((state) => state.persistedReducer.cart?.cart);
  const [loading, setLoading] = useState(true);

  let handleChange = (e, cart_id) => {
    updateCart(cart_id, e.target.value);
  };
  useEffect(() => {
    getCartlist();
  }, []);
  const getCartlist = async () => {
    setLoading(true)
    const data = {
      user_id: user?.user_id ? user?.user_id : "",
      session_id: sessionid ? sessionid : "",
      token: user?.token ? user?.token : "",
    };
    const res = await postData("/order/cart_list", data);

    if (res.data.status == 1) {
      setLoading(false)
      dispatch(cartData(res.data.data));
    } else {
      console.log(res.data.message);
    }
  };
  const checkout = async () => {
    const data = {
      user_id: user?.user_id ? user?.user_id : "",
    };
    const res = await postData("/order/enquiry", data);
    if (res.data.status == 1) {
      dispatch(cartCountvalue(0));
      swal(res.data.message, "", "success");
      navigate("/")
    } else {
      swal(res.data.message, "", "error");
    }
  }

  // const checkout = () => {
  //   const data1 = {
  //     coupan_code: "",
  //     gift_message: "",
  //     instructions: "",
  //     shippingoptionsid: cartlist ? cartlist?.shipping_option_id : "",
  //     shippingoptionsname: cartlist ? cartlist?.shipping_option_name : "",
  //     shipping_cost: cartlist ? cartlist?.shipping : 0,
  //   };
  //   dispatch(checkoutData(data1));
  //   navigate("/checkout-shipping");
  // };
  const removefromcartlist = async (cart_id) => {
    const data = {
      cart_id: cart_id,
      user_id: user.user_id ? user.user_id : "",
      session_id: sessionid ? sessionid : "",
      token: user ? user.token : "",
    };
    const res = await postData("/order/remove_cart", data);

    if (res.data.status == 1) {
      dispatch(cartCountvalue(res.data.data.total_count));
      getCartlist();
    } else {
      console.log(res.data.message);
    }
  };

  const updateCart = async (cart_id, q, value) => {
    const data = {
      cart_id: cart_id,
      user_id: user.user_id ? user.user_id : "",
      session_id: sessionid ? sessionid : "",
      quantity: q + value,
      token: user ? user.token : "",
    };

    const res = await postData("/order/update_cart", data);
    if (res.data.status == 1) {
      getCartlist();
      console.log(res);
    }
  };
  const increment = (res) => {
    const ishave = res?.quantity+ Number(res?.minimum_qty)  > res?.available_stock
    console.log('ishave',ishave);
    dispatch(cartAddquantity(res.product_id));
    // res?.quantity <= res?.available_stock &&
    updateCart(res.cart_id, res.quantity, Number(res.minimum_qty));
     }

  return (
    <>
      <div className="rt_cart_section_bg">
        <Container className="rt_custome_container">
          <Row className="w-100 m-auto">
            <Col>
              <div className="rt_cart_banner_content">
                <h1>Cart</h1>
                <ul>
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <BsArrowRight />
                  </li>
                  <li>
                    <NavLink to="#">Cart</NavLink>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="rt_cart_content">
        <Container className="rt_custome_container">
          {loading ? <Row className="w-100 m-auto"> 
            <Col xs={12} md={12} lg={9}>
            {[...Array(6)].map(val =>
              isTablet ? 
              <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', marginBottom: "15px" }} variant="text" animation="wave" height={280} /> :
              <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', marginBottom: "15px" }} variant="text" animation="wave" height={225} />
              )}</Col>
            <Col xs={12} md={12} lg={3}>
            <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', marginBottom: "15px" }} variant="text" animation="wave" height={400} />
            </Col>
          </Row>:
          cartitems?.length ? (
          <Row className="w-100 m-auto">
            <Col xs={12} md={12} lg={9}>
              {cartitems?.map((res) => (
                <div className="rt_cart_content_left">
                  <ul>
                    <li>
                      <div className="rt_cart_content_top position-relative">
                        <NavLink to={`/product-detail/${res.slug}`}>
                          <Image
                            src={res.product_image}
                            alt="Royal Trading Product"
                          ></Image>
                        </NavLink>
                        {/* <div className="tag-container">
                                                <div className="tag tag-drop">
                                                    <h3 className="tag-title">25% OFF</h3>
                                                    <div className="tag-tail"></div>
                                                </div>
                                            </div> */}
                      </div>
                    </li>
                    <li className="flex-grow-1">
                      <div className="rt_cart_heading">
                        <NavLink to={`/product-detail/${res.slug}`}>
                          <h2>{res.product_name}</h2>
                        </NavLink>
                        {/* <ul className='rt_rating1'>
                                                <li><h3><BsStarFill /> 4.5</h3></li>
                                                <li className='rt_line'></li>
                                                <li><h5>182</h5></li>
                                            </ul>
                                            <p>$75 <del>$100</del></p> */}
                        <p>
                          {res.currency_icon}
                          {res.sale_price}
                        </p>
                        {/* <ul className="rt_other_cart_item">
                            <li>
                              <p>
                                Size: <strong>5X5</strong>
                              </p>
                            </li>
                            <li>
                              <p>
                                Color:{" "}
                                <span>
                                  <span className="dot"></span>
                                </span>
                              </p>
                            </li>
                            <li>
                              <p>
                                Type: <strong>Round</strong>
                              </p>
                            </li>
                          </ul> */}
                      </div>
                    </li>
                    <li className="rt_quantity">
                      <div className="rt_delete">
                        <IconButton
                          aria-label="delete"
                          onClick={() => removefromcartlist(res.cart_id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </div>
                      <div className="input-group rt_quantity1">
                        <div className="input-group-prepend">
                          <button
                        
                            className="btn btn-outline-primary"
                            type="button"
                            onClick={() => {
                              dispatch(cartLessquantity(res.product_id));
                              if (res.quantity > Number(res.minimum_qty)) {
                                updateCart(res.cart_id, res.quantity, -Number(res.minimum_qty));
                              }else{
                                removefromcartlist(res.cart_id)
                              }
                            }}
                          >
                            -
                          </button>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value={res.quantity}
                          onChange={(e) => handleChange(e, res.cart_id)}
                        />
                        <div className="input-group-prepend">
                          <button
                            // disabled={ res?.quantity = res?.available_stock?true:false}
                            className="btn btn-outline-primary"
                            type="button"
                            // onClick={()=>res?.quantity < res?.available_stock && increment(res)}
                        
                            onClick={() => {
                              const ishave = res?.quantity+ Number(res?.minimum_qty)  > res?.available_stock
                               console.log('ishaz',ishave);
                               if (!ishave) {
                                //  res?.quantity !== res?.available_stock &&
                              dispatch(cartAddquantity(res.product_id));
                              // res?.quantity <= res?.available_stock &&
                              updateCart(res.cart_id, res.quantity, Number(res.minimum_qty));
                               }
                             
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              ))}
            </Col>
            <Col xs={12} md={12} lg={3}>
              <div className="rt_cart_content_right">
                <h2>Order Summary</h2>
                <ul className="rt_payment_details">
                  <li>
                    <p>Subtotal</p>
                  </li>
                  <li>
                    <p>
                      {icon}
                      {cartlist.subtotal}
                    </p>
                  </li>
                </ul>
                <ul className="rt_payment_details">
                  <li>
                    <p>Shipping Charges</p>
                  </li>
                  <li>
                    <p>
                      {icon}
                      {cartlist.shipping}
                    </p>
                  </li>
                </ul>
                {/* <ul className='rt_payment_details'>
                                    <li><p>Coupon Discount</p></li>
                                    <li><p>$0</p></li>
                                </ul> */}
                <ul className="rt_payment_details">
                  <li>
                    <p>Sales tax Estimate</p>
                  </li>
                  <li>
                    <p>
                      {icon}
                      {cartlist.tax}
                    </p>
                  </li>
                </ul>
                <ul className="rt_payment_total">
                  <li>
                    <p>
                      <strong>Total Amount</strong>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>
                        {icon}
                        {cartlist.total_price}
                      </strong>
                    </p>
                  </li>
                </ul>
                <Button
                  className="rt_checkout_btn"
                  onClick={() => {
                    checkout();
                  }}
                >
                  Checkout
                </Button>
                {/* <Button
                  className="rt_checkout_btn"
                  onClick={() => {
                    checkout();
                    navigate("/checkout");
                  }}
                >
                  proceed to checkout
                </Button> */}
              </div>
              <div className="rt_cart_bottom">
                <h2>Need Help?</h2>
                <p>Shipping</p>
                <p>Privacy Policy</p>
                {/* <h2>Returns</h2>
                <p>
                  Our policy includes returning an item to any of our retail
                  stores.
                </p> */}
              </div>
            </Col>
          </Row>
          ) : (
          <div className="rt_no_data">
            <div>
              <img src={Nodata} />
              <h4>  No data Available </h4>
            </div>
          </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default Cart;
