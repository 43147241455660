import React, { useState } from 'react';
import '../Assets/Css/Static.css';
import { FiPhoneCall } from "react-icons/fi";
import { MdOutlineMailOutline } from "react-icons/md";
import { Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { postData } from '../Helpers/request';
import swal from 'sweetalert';
import { CircularProgress } from '@mui/material';

const Contact1 = () => {
    const [first_name, setFirst_name] = useState('');
    const [subject, setSubject] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [comment, setComment] = useState('');

    const formsubmit = async (e) => {
        e.preventDefault();
        var data = {
            first_name,
            subject,
            email,
            phone,
            comment
        }
        const response = await postData('/common/contact', data)
           
                if (response.data.status == 1) {
                    setFirst_name("");
                    setSubject("");
                    setEmail("");
                    setComment("");
                    setPhone("");
                    return swal(response.data.message, "", "success");
                } else {
                    return swal(response.data.message, "", "error");
                }
    }
    const [buttonLoading, setButtonLoading] = useState(false)
    return (
        <>
            <div className='rt_contact_main'>
                <Container className='rt_custome_container'>
                    <div className='rt_contact_section'>
                        <Row className='m-auto w-100'>                        
                            <Col xs={12} md={6}>
                                <h2>get a quote</h2>
                                <p>Fill up the form and our team will get back to you within 24 hours.</p>
                                <ul>
                                    <li><FiPhoneCall /> <span>773-696-9552 </span></li>
                                    <li><MdOutlineMailOutline /> <span>royalchicago@gmail.com </span></li>
                                </ul>
                                <iframe src="https://www.google.com/maps/embed?pb=!4v1655279764166!6m8!1m7!1spP3QmkjaKsdvzHIHcL9m7A!2m2!1d41.80681078275263!2d-87.70662343198178!3f184.78390628642177!4f4.47783252417176!5f0.7820865974627469" width="100%" height="350" style={{border:'0',borderRadius: '10px'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </Col>
                            <Col xs={12} md={6}>
                                <div className='rt_contect_form rt_contact_content_left'>
                                    <h2>Send us an email</h2>
                                    <Form onSubmit={formsubmit}>
                                        <Row>
                                            <Col xl={6} lg={6} md={12} col={12}>
                                                <Form.Label htmlFor="basic-url">Your Name</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control placeholder="Enter Your Name" aria-label="Enter Your Name" aria-describedby="basic-addon1" value={first_name}
                                                onChange={(e) => setFirst_name(e.target.value)}
                                                required/>
                                                </InputGroup>
                                            </Col>
                                            <Col xl={6} lg={6} md={12} col={12}>
                                                <Form.Label htmlFor="basic-url">Email</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control placeholder="Enter Your Email" aria-label="Enter Your Email" aria-describedby="basic-addon1"   value={email}
                                                onChange={(e) => setEmail(e.target.value)} required/>
                                                </InputGroup>
                                            </Col>
                                            <Col xl={6} lg={6} md={12} col={12}>
                                                <Form.Label htmlFor="basic-url">Subject</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control placeholder="Select Subject" aria-label="Select Subject" aria-describedby="basic-addon1"  value={subject}
                                                onChange={(e) => setSubject(e.target.value)} required/>
                                                </InputGroup>
                                            </Col>
                                            <Col xl={6} lg={6} md={12} col={12}>
                                                <Form.Label htmlFor="basic-url">Phone</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control placeholder="Enter Phone Number" aria-label="Enter Phone Number" aria-describedby="basic-addon1" value={phone}
                                                onChange={(e) => setPhone(e.target.value)} required/>
                                                </InputGroup>
                                            </Col>
                                            <Col xl={12} lg={12} md={12} col={12}>
                                                <Form.Label htmlFor="basic-url">Message</Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control as="textarea" rows='5' placeholder="Write here...." aria-label="With textarea"  value={comment}
                                                onChange={(e) => setComment(e.target.value)} />
                                                </InputGroup>
                                            </Col>
                                            <Col xs={12}>
                                                <Button type="submit" className='rt_checkout_btn' disabled={buttonLoading}>{buttonLoading ? <CircularProgress className="mj_loader" /> : "Submit"}</Button>
                                            </Col>
                                        </Row>                                        
                                    </Form>
                                </div>
                            </Col>                        
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    );
}

export default Contact1;
