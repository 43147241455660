import React, { useState, useEffect } from "react";
import "../Assets/Css/product-details.css";
import { Col, Container, Row, Button } from "react-bootstrap";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { SideBySideMagnifier } from "react-image-magnifiers";
import ImageGallery from "react-image-gallery";
import { AiOutlineShoppingCart, AiOutlineInfoCircle } from "react-icons/ai";
import { NavLink } from "react-router-dom";
import { Breadcrumbs, Typography } from "@material-ui/core";
import { postData } from "../Helpers/request";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { whishlistlength, wishlistData } from "../Reducer/wishlistReducer";
import { cartCountvalue } from "../Reducer/cartReducer";
import { productDetailFilter } from "../Reducer/productReducers";
import { Skeleton } from "@mui/material";
import Videoimg from "../Assets/Images/example-360video-thumb.png";

const ProductDetails = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [state, setState] = useState({
    productdetails: [],
    images: [],
    currentImageUrl: [],
    currentImagetype: null,
  });
  const user = useSelector((state) => state.persistedReducer.user?.userData);
  const sessionid = useSelector(
    (state) => state.persistedReducer.home?.sessionId
  );

  useEffect(() => {
    productdetail();
    getProductDetailsFilter();
  }, []);

  const productdetail = async () => {
    setLoading(true);
    const data = {
      slug: params.slug,
      selecteddata: [],
      user_id: user?.user_id ? user?.user_id : "",
      session_id: sessionid ? sessionid : "",
    };
    const res = await postData("/product/productdetail", data);
    if (res.data.status == 1) {
      setLoading(false);
      const arr = [];
      res.data.data?.product_image?.map((val) => {
        if (val.type == "2") {
          var obj = { original: val.url, thumbnail: Videoimg, type: val.type };
          arr.push(obj);
        } else {
          var obj = { original: val.url, thumbnail: val.url, type: val.type };
          arr.push(obj);
        }
      });
      setState({
        ...state,
        currentImageUrl: res.data.data?.product_image[0]?.url,
        currentImagetype: res.data.data?.product_image[0]?.type,
        productdetails: res.data.data,
        images: arr,
      });
      console.log(state);
    }
  };

  const getProductDetailsFilter = async () => {
    const res = await postData("/product/productdetailfilter");

    if (res.data.status == 1) {
      dispatch(productDetailFilter(res.data.data));
    } else {
      console.log(res.data.message);
    }
  };
  const addtocart = async (product_id, quantity) => {
    const data = {
      user_id: user.user_id ? user.user_id : "",
      session_id: sessionid ? sessionid : "",
      product_id,
      quantity: quantity,
      ring_size: "",
      engraving_text: "",
      engraving_font: "",
      token: user ? user.token : "",
      type: "",
    };
    const res = await postData("/order/add_to_cart", data);

    if (res.data.status == 1) {
      dispatch(cartCountvalue(res.data.data.total_count));
      history("/cart");
      console.log(res.data.message);
    } else {
      console.log(res.data.message);
    }
  };

  const addtowishlist = async (val, wishindex) => {
    const data = {
      user_id: user.user_id ? user.user_id : "",
      session_id: sessionid ? sessionid : "",
      product_id: state.productdetails?.product_id,
      type: "",
      token: user ? user.token : "",
    };
    const res = await postData("/order/add_to_wishlist", data);

    if (res.data?.status == 1) {
      dispatch(whishlistlength(res.data.data.total_count));
      dispatch(wishlistData(res.data.data));

      const productlist = Object.assign(state.productdetails, {
        is_wishlist: res.data.data.wishlist_id,
      });
      setState({
        ...state,
        productdetails: productlist,
      });
    } else {
      console.log(res.data?.message);
    }
  };

  // A function to set the the image in focus that was clicked:
  const setView = (event, index) => {
    setState({
      ...state,
      currentImageUrl: state.images[index].original,
      currentImagetype: state.images[index].type,
    });
  };
  return (
    <>
      <Container className="rt_custome_header_container">
        <Row>
          <Col className="rt_breadcrumb">
            {loading ? (
              <Skeleton
                sx={{ bgcolor: "#e4e4e4" }}
                style={{ transform: "unset", marginBottom: "10px" }}
                variant="text"
                animation="wave"
                width={280}
                height={25}
              />
            ) : (
              <Breadcrumbs aria-label="breadcrumb">
                <NavLink underline="hover" color="inherit" to="/">
                  Home <BsArrowRight />
                </NavLink>
                <NavLink
                  underline="hover"
                  color="inherit"
                  to="#"
                  onClick={() => history(-1)}
                >
                  Product <BsArrowRight />
                </NavLink>
                <Typography color="text.primary">Product Details</Typography>
              </Breadcrumbs>
            )}
          </Col>
        </Row>
      </Container>

      <section className="rt_product_details_section_one">
        <Container className="rt_custome_header_container">
          <Row>
            <Col lg={7}>
              <Row>
                <Col lg={2} md={2} xs={12} className="rt_order_2">
                  {loading ? (
                    <>
                      <Skeleton
                        sx={{ bgcolor: "#e4e4e4" }}
                        style={{ transform: "unset", marginBottom: "10px" }}
                        variant="text"
                        animation="wave"
                        height={115}
                      />
                      <Skeleton
                        sx={{ bgcolor: "#e4e4e4" }}
                        style={{ transform: "unset", marginBottom: "10px" }}
                        variant="text"
                        animation="wave"
                        height={115}
                      />
                      <Skeleton
                        sx={{ bgcolor: "#e4e4e4" }}
                        style={{ transform: "unset", marginBottom: "10px" }}
                        variant="text"
                        animation="wave"
                        height={115}
                      />
                    </>
                  ) : (
                    <div className="rt_product_details_slider_thumb">
                      <ImageGallery
                        thumbnailPosition="top"
                        items={state.images}
                        onThumbnailClick={setView}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showNav={false}
                      />
                    </div>
                  )}
                </Col>
                <Col lg={10} md={10} xs={12} className="rt_order_1">
                  {loading ? (
                    <Skeleton
                      sx={{ bgcolor: "#e4e4e4" }}
                      style={{ transform: "unset", marginBottom: "10px" }}
                      variant="text"
                      animation="wave"
                      height={600}
                    />
                  ) : (
                    <div className="rt_product_details_slider_main">
                      {state.currentImagetype == 1 ? (
                        <SideBySideMagnifier
                          alwaysInPlace={true}
                          className="image-to-magnify"
                          fillAvailableSpace={false}
                          imageSrc={state.currentImageUrl}
                          largeImageSrc={state.currentImageUrl}
                        />
                      ) : (
                        <div className="video-wrapper">
                          <video
                            width="100%"
                            src={state.currentImageUrl}
                            frameBorder="0"
                            allowFullScreen
                            playsinline="true"
                            autoPlay="true"
                            loop
                          ></video>
                        </div>
                      )}
                    </div>
                  )}
                </Col>
                {/* product text details section tab and mobile view */}
                <Col
                  lg={12}
                  md={12}
                  className="ml-5 d-lg-none d-xl-none d-md-block d-xs-block rt_order_3"
                >
                  <div className="rt_product_details_text_content_main">
                    <div className="rt_product_details_title">
                      <div>
                        <h2>{state.productdetails?.name}</h2>
                        <p>SKU:{state.productdetails?.sku}</p>
                      </div>
                      <div>
                        <button className="rt_product_details_wishlist_icn">
                          {state.productdetails?.is_wishlist !== 0 ? (
                            <AiFillHeart style={{ color: "red" }} />
                          ) : (
                            <AiOutlineHeart />
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="rt_product_price_review_main">
                      {loading ? (
                        <Skeleton
                          sx={{ bgcolor: "#e4e4e4" }}
                          style={{ transform: "unset", marginBottom: "10px" }}
                          variant="text"
                          animation="wave"
                          height={100}
                        />
                      ) : (
                        <div className="rt_product_details_price_text">
                          <h3>
                            {user.isLogin
                              ? state.productdetails?.currency_icon +
                                "" +
                                state.productdetails?.sale_price
                              : ""}
                          </h3>
                        </div>
                      )}
                      {loading ? (
                        <Skeleton
                          sx={{ bgcolor: "#e4e4e4" }}
                          style={{ transform: "unset", marginBottom: "10px" }}
                          variant="text"
                          animation="wave"
                          height={100}
                        />
                      ) : (
                        <div className="rt_product_details_price_text">
                          <h4>
                            Minimum Order Quantity :{" "}
                            {state.productdetails?.minimum_qty}
                          </h4>
                          <h4>
                            Available Quantity :{" "}
                            {state.productdetails?.available_stock}
                          </h4>
                          {
                      state.productdetails?.description.length ?
                       <h4 className="ryl_details_description">
                      <strong>Description : </strong> {" "}
                        {state.productdetails?.description}
                      </h4>:''
                      }
                        </div>
                      )}
                    </div>

                    {/* <div className="rt_product_details_size_slider">
                      <h3> Select Size </h3>

                      <Slider {...SettingSize}>
                        <Col>
                          <div className="rt_product_details_size_slide_item_box">
                            <p className="rt_pd_p-1">5x5</p>
                            <p className="rt_pd_p-2"> $132.5</p>
                          </div>
                        </Col>
                        <Col>
                          <div className="rt_product_details_size_slide_item_box">
                            <p className="rt_pd_p-1">5x8</p>
                            <p className="rt_pd_p-2"> $132.5</p>
                          </div>
                        </Col>
                        <Col>
                          <div className="rt_product_details_size_slide_item_box">
                            <p className="rt_pd_p-1">6x9</p>
                            <p className="rt_pd_p-2"> $132.5</p>
                          </div>
                        </Col>
                        <Col>
                          <div className="rt_product_details_size_slide_item_box">
                            <p className="rt_pd_p-1">8x10</p>
                            <p className="rt_pd_p-2"> $132.5</p>
                          </div>
                        </Col>
                        <Col>
                          <div className="rt_product_details_size_slide_item_box">
                            <p className="rt_pd_p-1">9x12</p>
                            <p className="rt_pd_p-2"> $132.5</p>
                          </div>
                        </Col>

                        <Col>
                          <div className="rt_product_details_size_slide_item_box">
                            <p className="rt_pd_p-1">5x5</p>
                            <p className="rt_pd_p-2"> $132.5</p>
                          </div>
                        </Col>
                        <Col>
                          <div className="rt_product_details_size_slide_item_box">
                            <p className="rt_pd_p-1">5x8</p>
                            <p className="rt_pd_p-2"> $132.5</p>
                          </div>
                        </Col>
                        <Col>
                          <div className="rt_product_details_size_slide_item_box">
                            <p className="rt_pd_p-1">6x9</p>
                            <p className="rt_pd_p-2"> $132.5</p>
                          </div>
                        </Col>
                        <Col>
                          <div className="rt_product_details_size_slide_item_box">
                            <p className="rt_pd_p-1">8x10</p>
                            <p className="rt_pd_p-2"> $132.5</p>
                          </div>
                        </Col>
                        <Col>
                          <div className="rt_product_details_size_slide_item_box">
                            <p className="rt_pd_p-1">9x12</p>
                            <p className="rt_pd_p-2"> $132.5</p>
                          </div>
                        </Col>
                      </Slider>
                    </div>

                    <div className="rt_product_details_color">
                      <h3>Select Color</h3>
                      <label className="color_label">
                        <input
                          type="radio"
                          defaultChecked="checked"
                          name="radio"
                        />
                        <span
                          className="checkmark"
                          style={{ backgroundColor: "black" }}
                        />
                      </label>
                      <label className="color_label">
                        <input type="radio" name="radio" />
                        <span
                          className="checkmark"
                          style={{ backgroundColor: "#fab3b3" }}
                        />
                      </label>
                      <label className="color_label">
                        <input type="radio" name="radio" />
                        <span
                          className="checkmark"
                          style={{ backgroundColor: "#be90d3" }}
                        />
                      </label>
                      <label className="color_label">
                        <input type="radio" name="radio" />
                        <span
                          className="checkmark"
                          style={{ backgroundColor: "#c7c7c7" }}
                        />
                      </label>
                    </div>

                    <div className="rt_product_details_select_type">
                      <h3>Select Type</h3>
                      <ul className="rt_product_details_select_type_ul">
                        <li>
                          <Button className="rt_select_type_btn">Round</Button>
                        </li>
                        <li>
                          <Button className="rt_select_type_btn">
                            Rectangle
                          </Button>
                        </li>
                        <li>
                          <Button className="rt_select_type_btn">Runner</Button>
                        </li>
                        <li>
                          <Button className="rt_select_type_btn">Square</Button>
                        </li>
                      </ul>
                    </div> */}

                    <div className="rt_product_details_cart_btn_main">
                      <Button
                        onClick={() => {
                          user?.isLogin
                            ? addtocart(
                                state.productdetails?.product_id,
                                state.productdetails?.minimum_qty
                              )
                            : history("/login");
                        }}
                      >
                        <AiOutlineShoppingCart />
                        ADD TO Cart
                        <BsArrowRight />
                      </Button>
                    </div>

                    <div className="rt_product_details_replacement_content">
                      {/* <p>
                        <AiOutlineInfoCircle /> 30 Day Easy Returns
                      </p> */}
                      <p>
                        <AiOutlineInfoCircle /> Low Price Commitment
                      </p>
                    </div>
                  </div>
                </Col>
                {/* product text details section tab and mobile view  */}
              </Row>

              <Row>
                <Col lg={12}>
                  {state?.productdetails?.height === null ||
                  state?.productdetails?.width === null ||
                  state?.productdetails?.length === null ||
                  state?.productdetails?.weight === null ? (
                    ""
                  ) : (
                    <div className="rt_product_overview_main">
                      <div className="rt_product_overview_title">
                        <h2>Product Overview</h2>
                        <h3>Description</h3>
                        <p>{state.productdetails?.description}</p>
                      </div>

                      <div className="rt_product_specifications">
                        <h3>Specifications</h3>
                        <div className="rt_product_specifications_list">
                          <ul>
                            <li>
                              <h4> Height: </h4>
                              <p> {state.productdetails?.height} </p>
                            </li>
                            <li>
                              <h4> Width: </h4>
                              <p>{state.productdetails?.width} </p>
                            </li>
                            <li>
                              <h4> Weight: </h4>
                              <p> {state.productdetails?.weight}</p>
                            </li>
                            <li>
                              <h4> Length: </h4>
                              <p>{state.productdetails?.length}</p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      {/* <div className='rt_product_review_main'>
                                            <h3>Reviews</h3>
                                            <div className='rt_product_review_btns_main'>
                                                <div className='rt_product_review_count_text'>
                                                    <span> 1–8 of 3219 Reviews </span>
                                                </div>
                                                <div className='rt_product_review_btns_div'>

                                                    <div className='rt_product_details_shortby'>

                                                        <InputGroup className="">
                                                            <InputGroup.Text id="basic-addon1">SORT BY :</InputGroup.Text>
                                                            <Form.Select aria-label="Default select example">
                                                                <option>Most Relevant</option>
                                                                <option value="1">One</option>
                                                                <option value="2">Two</option>
                                                                <option value="3">Three</option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </div>

                                                    <Button className='rt_product_review_btn'> Write a review </Button>
                                                </div>
                                            </div>

                                            <div className='rt_product_review_box_main'>
                                                <div className='rt_product_review_box'>
                                                    <div className='rt_product_review_rating_and_date'>
                                                        <div className='rt_product_review_star'>
                                                            <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                                                        </div>
                                                        <div className='rt_product_review_date'>
                                                            <span> April 6, 2022</span>
                                                            <img src={Flag} />
                                                        </div>
                                                    </div>

                                                    <div className='rt_product_review_text_content'>
                                                        <h4>Great Experience</h4>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.</p>
                                                        <ul>
                                                            <li>
                                                                <div className='rt_product_review_text_content_img'>
                                                                    <img src={RwImage1} />
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='rt_product_review_text_content_img'>
                                                                    <img src={RwImage2} />
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='rt_product_review_text_content_img'>
                                                                    <img src={RwImage3} />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className='rt_product_review_user'>
                                                        <img src={User} /> <span> Layka Parker</span>
                                                    </div>
                                                </div>
                                                <div className='rt_product_review_box'>
                                                    <div className='rt_product_review_rating_and_date'>
                                                        <div className='rt_product_review_star'>
                                                            <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                                                        </div>
                                                        <div className='rt_product_review_date'>
                                                            <span> April 6, 2022</span>
                                                            <img src={Flag} />
                                                        </div>
                                                    </div>

                                                    <div className='rt_product_review_text_content'>
                                                        <h4>Great Experience</h4>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.</p>
                                                        <ul>
                                                            <li>
                                                                <div className='rt_product_review_text_content_img'>
                                                                    <img src={RwImage1} />
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='rt_product_review_text_content_img'>
                                                                    <img src={RwImage2} />
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='rt_product_review_text_content_img'>
                                                                    <img src={RwImage3} />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className='rt_product_review_user'>
                                                        <img src={User} /> <span> Layka Parker</span>
                                                    </div>
                                                </div>
                                                <div className='rt_product_review_box'>
                                                    <div className='rt_product_review_rating_and_date'>
                                                        <div className='rt_product_review_star'>
                                                            <Rating name="half-rating-read" defaultValue={5} precision={0.5} readOnly />
                                                        </div>
                                                        <div className='rt_product_review_date'>
                                                            <span> April 6, 2022</span>
                                                            <img src={Flag} />
                                                        </div>
                                                    </div>

                                                    <div className='rt_product_review_text_content'>
                                                        <h4>Great Experience</h4>
                                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown.</p>
                                                        <ul>
                                                            <li>
                                                                <div className='rt_product_review_text_content_img'>
                                                                    <img src={RwImage1} />
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='rt_product_review_text_content_img'>
                                                                    <img src={RwImage2} />
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className='rt_product_review_text_content_img'>
                                                                    <img src={RwImage3} />
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className='rt_product_review_user'>
                                                        <img src={User} /> <span> Layka Parker</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='text-center'>
                                                <Button className='review_load_btn'>
                                                    LOAD MORE
                                                </Button>
                                            </div>

                                        </div> */}
                    </div>
                  )}
                </Col>
              </Row>
            </Col>

            {/* product text details section desktop view  */}
            <Col lg={5} className="ml-5 d-none d-lg-block ">
              <div className="rt_product_details_text_content_main">
                <div className="rt_product_details_title">
                  <div>
                    {loading ? (
                      <Skeleton
                        sx={{ bgcolor: "#e4e4e4" }}
                        style={{ transform: "unset", marginBottom: "10px" }}
                        variant="text"
                        animation="wave"
                        width={300}
                        height={40}
                      />
                    ) : (
                      <>
                        <h2>{state.productdetails.name}</h2>
                        <p className="rt-sku">
                          SKU: {state.productdetails?.sku}
                        </p>
                      </>
                    )}
                  </div>
                  <div>
                    {loading ? (
                      <Skeleton
                        sx={{ bgcolor: "#e4e4e4" }}
                        style={{ transform: "unset", borderRadius: "50%" }}
                        variant="text"
                        animation="wave"
                        width={40}
                        height={40}
                      />
                    ) : (
                      <button
                        className="rt_product_details_wishlist_icn"
                        onClick={() => addtowishlist()}
                      >
                        {state.productdetails?.is_wishlist !== 0 ? (
                          <AiFillHeart style={{ color: "#F3759E" }} />
                        ) : (
                          <AiOutlineHeart />
                        )}
                      </button>
                    )}
                  </div>
                </div>

                <div className="rt_product_price_review_main">
                  {/* <div className='rt_product_details_p_review'>
                                        <div className='rt_pd_review_btn'>
                                            <GrStar /> <p> 3.1 </p> <span>182</span>
                                        </div>
                                    </div> */}
                  {loading ? (
                    <Skeleton
                      sx={{ bgcolor: "#e4e4e4" }}
                      style={{ transform: "unset", marginBottom: "10px" }}
                      variant="text"
                      animation="wave"
                      width={150}
                      height={40}
                    />
                  ) : (
                    <div className="rt_product_details_price_text">
                      {/* <h3> $75 <span> $100 </span></h3> */}
                      <h3>
                        {user.isLogin
                          ? state.productdetails?.currency_icon +
                            "" +
                            state.productdetails?.sale_price
                          : ""}
                      </h3>
                    </div>
                  )}
                  {loading ? (
                    <Skeleton
                      sx={{ bgcolor: "#e4e4e4" }}
                      style={{ transform: "unset", marginBottom: "10px" }}
                      variant="text"
                      animation="wave"
                      width={150}
                      height={40}
                    />
                  ) : (
                    <div className="rt_product_details_price_text">
                      <h4>
                        Minimum Order Quantity :{" "}
                        {state.productdetails?.minimum_qty}
                      </h4>
                      <h4>
                        Available Quantity :{" "}
                        {state.productdetails?.available_stock}
                      </h4>
                      {
                      state.productdetails?.description.length ?
                       <h4 className="ryl_details_description">
                      <strong>Description : </strong> {" "}
                        {state.productdetails?.description}
                      </h4>:''
                      }
                    </div>
                  )}
                </div>

                <div className="rt_product_details_cart_btn_main">
                  {loading ? (
                    <Skeleton
                      sx={{ bgcolor: "#e4e4e4" }}
                      style={{ transform: "unset", marginBottom: "10px" }}
                      variant="text"
                      animation="wave"
                      height={70}
                    />
                  ) : (
                    <Button
                      onClick={() => {
                        user?.isLogin
                          ? addtocart(
                              state.productdetails?.product_id,
                              state.productdetails?.minimum_qty
                            )
                          : history("/login");
                      }}
                    >
                      <AiOutlineShoppingCart />
                      ADD TO Cart
                      <BsArrowRight />
                    </Button>
                  )}
                </div>

                <div className="rt_product_details_replacement_content">
                  {/* <p>
                    <AiOutlineInfoCircle /> 30 Day Easy Returns
                  </p> */}
                  <p>
                    <AiOutlineInfoCircle /> Low Price Commitment
                  </p>
                </div>
              </div>
            </Col>
            {/* product text details section desktop view  */}
          </Row>
        </Container>
      </section>

      {/* You Might Also Like section start */}
      {/* <section className='rt_product_details_product_section'>
                <div className='rt_product_list rt_product_wishlist'>
                    <Container className='rt_custome_container'>
                        <Row className='w-100 m-auto'>
                            <Col lg={12}>
                                <div className='rt_product_you_might_title'>
                                    <h2> You Might Also Like </h2>
                                    <p> Customers who viewed the Gray Vintage  Medallion <br /> Washable Rug also liked the following </p>
                                </div>
                            </Col>
                            <Col lg={3} md={4} xs={6}>
                                <div className='rt_product_list_content'>
                                    <div className='rt_product_list_content_top position-relative'>
                                        <NavLink to="#">
                                            <Image src={productImage} alt="Royal Trading Product"></Image>
                                        </NavLink> */}
      {/* <ul className='rt_rating'>
                                            <li><h3><BsStarFill /> 4.5</h3></li>
                                            <li className='rt_line'></li>
                                            <li><h5>182</h5></li>
                                        </ul> */}
      {/* </div> */}
      {/* <div className="tag-container">
                                        <div className="tag tag-drop">
                                            <h3 className="tag-title">25% OFF</h3>
                                            <div className="tag-tail"></div>
                                        </div>
                                    </div> */}
      {/* <div className='rt_wishlist_icon'>
                                        <span><AiOutlineHeart /></span>
                                    </div>
                                    <div className='rt_product_list_heading'>
                                        <NavLink to='#'>
                                            <h2>Ria Geometric Washable Rug</h2>
                                        </NavLink> */}
      {/* <p>$75 <del>$100</del></p> */}
      {/* <p>$75</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} md={4} xs={6}>
                                <div className='rt_product_list_content'>
                                    <div className='rt_product_list_content_top position-relative'>
                                        <NavLink to="#">
                                            <Image src={productImage} alt="Royal Trading Product"></Image>
                                        </NavLink>                                        
                                    </div>
                                    <div className='rt_wishlist_icon'>
                                        <span><AiOutlineHeart /></span>
                                    </div>
                                    <div className='rt_product_list_heading'>
                                        <NavLink to='#'>
                                            <h2>Ria Geometric Washable Rug</h2>
                                        </NavLink>
                                        <p>$75</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} md={4} xs={6}>
                                <div className='rt_product_list_content'>
                                    <div className='rt_product_list_content_top position-relative'>
                                        <NavLink to="#">
                                            <Image src={productImage} alt="Royal Trading Product"></Image>
                                        </NavLink>
                                    </div>
                                    <div className='rt_wishlist_icon'>
                                        <span><AiOutlineHeart /></span>
                                    </div>
                                    <div className='rt_product_list_heading'>
                                        <NavLink to='#'>
                                            <h2>Ria Geometric Washable Rug</h2>
                                        </NavLink>
                                        <p>$75</p>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3} md={4} xs={6}>
                                <div className='rt_product_list_content'>
                                    <div className='rt_product_list_content_top position-relative'>
                                        <NavLink to="#">
                                            <Image src={productImage} alt="Royal Trading Product"></Image>
                                        </NavLink>
                                    </div>
                                    <div className='rt_wishlist_icon'>
                                        <span><AiOutlineHeart /></span>
                                    </div>
                                    <div className='rt_product_list_heading'>
                                        <NavLink to='#'>
                                            <h2>Ria Geometric Washable Rug</h2>
                                        </NavLink>
                                        <p>$75</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section> */}
      {/* You Might Also Like section end */}
    </>
  );
};

export default ProductDetails;
