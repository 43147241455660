import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import Slider from "react-slick";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Categories = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const bannerData = useSelector(
    (state) => state.persistedReducer.home.bannerData?.category
  );
  return (
    <>
      <div className="rt_categories_section">
        <Container className="rt_custome_container">
          <Row className="m-auto w-100">
            <Col>
              <div className="rt_heading">
                <h2>Explore Our Categories</h2>
              </div>
            </Col>
          </Row>
          <Row className="m-auto w-100">
            <Col>
              <div className="rt_categories_slider">
                <Slider {...settings}>
                  {bannerData?.map((res) => (
                    <div key={res.name} className="rt_categories_content">
                      <NavLink to={res?.slug}>
                        <Image src={res.image} alt={res.name}></Image>
                        <h3>{res.name} </h3>
                      </NavLink>
                    </div>
                  ))}
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Categories;
