import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    cartcount: "",
    cartData: [],
    cart: [],
    quantity: 1,
  },
  reducers: {
    cartCountvalue: (state, action) => {
      state.cartcount = action.payload;
    },
    cartData: (state, action) => {
      state.cartData = action.payload;
      state.cart = action.payload == [] ? [] : action.payload.cart_data;
      state.cartcount = action.payload.cart_data === [] ? "" : state.cartcount;
    },
    cartDetails: (state, action) => {
      state.cartDetails = action.payload;
    },
    cartAddquantity: (state, action) => {
      state.cart.map((res) => {
        if (res.product_id == action.payload) {
          return { ...state.cart, quantity: res.quantity += Number(res.minimum_qty) };
        }
      });
    },
    cartLessquantity: (state, action) => {
      state.cart.map((res) => {
        if (res.product_id == action.payload && res.quantity > Number(res.minimum_qty)) {
          return { ...state.cart, quantity: res.quantity -= Number(res.minimum_qty) };
        }
      });
    },
  },
});

export const {
  cartCountvalue,
  cartData,
  cartDetails,
  cartAddquantity,
  cartLessquantity,
} = cartSlice.actions;
export default cartSlice.reducer;
