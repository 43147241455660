import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import rugImage1 from '../Assets/Images/Home/rugs/rugs1.png';
import rugImage2 from '../Assets/Images/Home/rugs/rugs2.png';
import rugImage3 from '../Assets/Images/Home/rugs/rugs3.png';
import rugImage4 from '../Assets/Images/Home/rugs/rugs4.png';
import { NavLink } from "react-router-dom";

const RugsSize = () => {
    return (
        <>
            <div className='rt_rugsize_section'>
                <Container className='rt_custome_container'>
                    <Row className='m-auto w-100'>
                        <Col>
                            <div className='rt_heading1'>
                                <h2>Rugs by Size</h2>
                                <p>The right size rug gives your room the perfect feel. We can help<br/> make it easy.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='m-auto w-100 justify-content-center'>
                        <Col xs={6} md={6} lg={3}>
                            <div className='rt_rugsize_content'>
                                <NavLink to='/product-category'>
                                    <Image src={rugImage1} alt="Rugs Size"></Image>
                                </NavLink>
                            </div>
                        </Col>
                        <Col xs={6} md={6} lg={3}>
                            <div className='rt_rugsize_content'>
                                <NavLink to='/product-category'>
                                    <Image src={rugImage2} alt="Rugs Size"></Image>
                                </NavLink>    
                            </div>
                        </Col>
                        <Col xs={6} md={6} lg={3}>
                            <div className='rt_rugsize_content'>
                                <NavLink to='/product-category'>
                                    <Image src={rugImage3} alt="Rugs Size"></Image>
                                </NavLink>    
                            </div>
                        </Col>
                        <Col xs={6} md={6} lg={3}>
                            <div className='rt_rugsize_content'>
                                <NavLink to='/product-category'>
                                    <Image src={rugImage4} alt="Rugs Size"></Image>
                                </NavLink>    
                            </div>
                        </Col>
                        
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default RugsSize;
