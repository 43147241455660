import { createSlice } from '@reduxjs/toolkit'

export const productSlice = createSlice({
  name: 'product',
  initialState: {
      productData:[],
      filterData:{},
      demoData:[],
      quickData:[],
      expanded:[],
      filterSelect:[],
      categoryList:'',
      wishListcount:0,
      cartListcount:0,
      productDetail:[],
      productDetailFilter:[],
      checkoutData:[],
      addFilterChip:[],
      category:[]
  },
  reducers: {
    productData: (state,action) => {
        state.productData = action.payload
    },
    productDetail: (state,action) => {
        state.productDetail = action.payload
    },
    productDetailFilter: (state,action) => {
        state.productDetailFilter = action.payload
    },
    filterData: (state,action) => {
        state.filterData = action.payload
    },
    demoData: (state,action) => {
        state.demoData = action.payload
    },
    quickData: (state,action) => {
        state.quickData = action.payload
    },
    expandedData: (state,action) => {
        state.expanded = action.payload
    },
    filterSelect: (state,action) => {
        console.log("....",action.payload)
        state.filterSelect = action.payload
    },
    categoryList: (state,action) => {
        state.categoryList = action.payload
    },
    wishListcount: (state,action) => {
        state.wishListcount = action.payload
    },
    cartListcount: (state,action) => {
        state.cartListcount = action.payload
    },
    checkoutData:(state,action) => {
        state.checkoutData = action.payload
    },
    addFilterChipdata:(state,action) =>{
        state.addFilterChip = action.payload
    },
    category: (state,action) =>{
        state.category = action.payload
    }
  },
})


export const { productData, filterData, demoData, quickData, expandedData, 
    filterSelect,  categoryList, wishListcount, cartListcount,productDetail,productDetailFilter,checkoutData,addFilterChipdata,category } = productSlice.actions

export default productSlice.reducer