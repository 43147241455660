import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../Assets/Css/Cart.css";
import { BsArrowRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { postData } from "../Helpers/request";
import swal from "sweetalert";
import { Divider, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { addressData } from "../Reducer/addressReducer";
import { loader } from "../Reducer/homeReducer";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Paypal from "./paypal";
import { toast } from "react-toastify";

const Checkout = () => {
  const [sfirst_name, setSfirst_name] = useState("");
  const [slast_name, setSlast_name] = useState("");
  const [sadd1, setSadd1] = useState("");
  const [sadd2, setSadd2] = useState("");
  const [scity, setScity] = useState("");
  const [sstate, setSstate] = useState("");
  const [sphone, setSphone] = useState(null);
  const [szip, setSzip] = useState(null);
  const [scountry, setScountry] = useState("");
  const [semail, setSemail] = useState("");
  const [bfirst_name, setBfirst_name] = useState("");
  const [blast_name, setBlast_name] = useState("");
  const [badd1, setBadd1] = useState("");
  const [badd2, setBadd2] = useState("");
  const [bcity, setBcity] = useState("");
  const [bstate, setBstate] = useState("");
  const [bphone, setBphone] = useState(null);
  const [bemail, setBemail] = useState("");
  const [bzip, setBzip] = useState(null);
  const [bcountry, setBcountry] = useState("");
  const [sameasshipping, setSameasshipping] = useState(false);
  const [shippingaddress, setShippingaddress] = useState(null);
  const [billingaddress, setBillingaddress] = useState(null);
  const [sadd_type, setSadd_type] = useState("Home");
  const [badd_type, setBadd_type] = useState("Home");
  const [addressshow, setAddressshow] = useState(false);
  const [add_firstname, setAdd_firstname] = React.useState("");
  const [add_lastname, setAdd_lastname] = React.useState("");
  const [add_phone, setAdd_phone] = React.useState("");
  const [add_email, setAdd_email] = React.useState("");
  const [add_address1, setAdd_address1] = React.useState("");
  const [add_address2, setAdd_address2] = React.useState("");
  const [add_city, setAdd_city] = React.useState("");
  const [add_state, setAdd_state] = React.useState("");
  const [add_postalcode, setAdd_postalcode] = React.useState("");
  const [add_company, setAdd_company] = React.useState("");
  const [add_country, setAdd_country] = React.useState("United States");
  const [add_name, setAdd_name] = React.useState("");
  const [add_landmark, setAdd_landmark] = useState("");
  const [add_ship_default, setAdd_ship_default] = useState(false);
  const [add_bill_default, setAdd_bill_default] = useState(false);
  const [add_type, setAdd_type] = useState("");
  const [formheading, setFormheading] = React.useState("");
  const [addressUpdate, setAddressUpdate] = useState(false);
  const [address_id, setAddress_id] = useState("");
  const [paypalpopup, setPaypalpopup] = useState(false);
  const [paypalreq, setPaypalreq] = React.useState([]);

  const addresslist = useSelector(
    (state) => state.persistedReducer.address?.addressData
  );
  const user = useSelector((state) => state.persistedReducer.user?.userData);
  const checkoutdata = useSelector(
    (state) => state.persistedReducerSession.product?.checkoutData
  );
  const cartlist = useSelector(
    (state) => state.persistedReducer.cart?.cartData
  );
  const sessionid = useSelector(
    (state) => state.persistedReducer.home?.sessionId
  );
  const dispatch = useDispatch();
  useEffect(() => {
    getaddress();
    setSadd_type("Home");
    setBadd_type("Home");
    addresslist.map((val) => {
      if (val.is_shipping_default != "0") {
        setShippingaddress(val.address_id);
      }
      if (val.is_billing_default != "0") {
        setBillingaddress(val.address_id);
      }
    });
  }, []);

  const getaddress = async () => {
    var data = {
      user_id: user?.user_id ? user?.user_id : 0,
      token: user?.token,
    };
    const response = await postData("/user/address_list", data);
    if (response.data.status == 1) {
      dispatch(addressData(response.data.data?.address));
      toast.success(response.data.message, { autoClose: 3000 });
    } else {
      toast.error(response.data.message, { autoClose: 3000 });
    }
  };
  const handleSubmit = async (e, type) => {
    e.preventDefault();
    dispatch(loader(true));
    const data = {
      user_id: user?.user_id ? user?.user_id : 0,
      first_name: add_firstname,
      last_name: add_lastname,
      phone: add_phone,
      email: add_email,
      address1: add_address1,
      address2: add_address2,
      city: add_city,
      state: add_state,
      zip: add_postalcode,
      company: add_company,
      country: add_country,
      name: add_name,
      token: user?.token,
      is_billing_default: add_bill_default ? 1 : 0,
      is_shipping_default: add_ship_default ? 1 : 0,
      address_type: add_type,
    };
    const response = await postData("/user/add_user_address", data);
    if (response.data.status == 1) {
      dispatch(loader(false));
      setAddressshow(false);
      //getaddress()
      dispatch(addressData(response.data.data?.address));
      console.log(response.data.message);
    } else {
      dispatch(loader(false));
      console.log(response.data.message);
    }
    resetAddress();
  };

  const editaddress = async (e, type) => {
    e.preventDefault();
    dispatch(loader(true));
    setAddressUpdate(false);
    const data = {
      address_id: address_id,
      user_id: user?.user_id ? user?.user_id : 0,
      first_name: add_firstname,
      last_name: add_lastname,
      phone: add_phone,
      email: add_email,
      address1: add_address1,
      address2: add_address2,
      city: add_city,
      state: add_state,
      zip: add_postalcode,
      company: add_company,
      country: add_country,
      name: add_name,
      token: user?.token,
      is_shipping_default: add_ship_default ? 1 : 0,
      is_billing_default: add_bill_default ? 1 : 0,
      address_type: add_type,
    };
    const response = await postData("/user/update_address", data);
    if (response.data.status == 1) {
      dispatch(loader(false));
      //getaddress();
      dispatch(addressData(response.data.data?.address));
      window.location.reload();
      console.log(response.data.message);
    } else {
      dispatch(loader(false));
      console.log(response.data.message);
    }
    resetAddress();
  };
  const resetAddress = () => {
    setFormheading("");
    setAdd_firstname("");
    setAdd_lastname("");
    setAdd_phone("");
    setAdd_email("");
    setAdd_address1("");
    setAdd_address2("");
    setAdd_city("");
    setAdd_state("");
    setAdd_postalcode("");
    setAdd_company("");
    setAdd_country("United States");
    setAdd_name("");
    setAdd_ship_default(false);
    setAdd_bill_default(false);
    setAdd_type("");
  };
  const checkout = async () => {
    const data = {
      user_id: user?.user_id ? user?.user_id : "",
      coupan_code: checkoutdata?.coupan_code ? checkoutdata?.coupan_code : "",
      session_id: sessionid ? sessionid : "",
      order_total: cartlist?.total_price,
      //gift_message: checkoutdata?.gift_message ? checkoutdata?.gift_message : '',
      //instructions: checkoutdata?.instructions ? checkoutdata?.instructions : '',
      tax_amount: cartlist?.tax,
      s_address_id: shippingaddress,
      b_address_id: billingaddress,
      shipping_cost: checkoutdata?.shipping_cost,
      shipping_option_id: checkoutdata?.shippingoptionsid,
      token: user?.token ? user?.token : "",
      password: "",
      email: user?.email ? user?.email : semail,
      sfirst_name,
      slast_name,
      sphone,
      saddress1: sadd1,
      saddress2: sadd2,
      scity,
      sstate,
      scountry,
      szip,
      bfirst_name: sameasshipping ? sfirst_name : bfirst_name,
      blast_name: sameasshipping ? slast_name : blast_name,
      bphone: sameasshipping ? sphone : bphone,
      baddress1: sameasshipping ? sadd1 : badd1,
      baddress2: sameasshipping ? sadd2 : badd2,
      bcity: sameasshipping ? scity : bcity,
      bstate: sameasshipping ? sstate : bstate,
      bcountry: sameasshipping ? scountry : bcountry,
      bzip: "",
      payment_mode: "",
      billing_address_type: sameasshipping ? sadd_type : badd_type,
      shipping_address_type: sadd_type,
    };
    const res = await postData("/order/buy", data);
    if (res.data.status == 1) {
      setPaypalreq(res.data.data);
      console.log("hii", res.data.data);
      setPaypalpopup(true);
      console.log(res.data.data);
      console.log(res.data.message);
    } else {
      console.log(res.data.message);
    }
  };

  const checkoutbutton = (e) => {
    if (!user?.isLogin) {
      e.preventDefault();
      checkout();
    } else {
      if (billingaddress && shippingaddress) {
        checkout();
      } else {
        if (!billingaddress && !shippingaddress) {
          swal(
            "Please select billing address, shipping address & payment method"
          );
        } else if (!billingaddress) {
          swal("Please select billing address");
        } else if (!shippingaddress) {
          swal("Please select shipping address");
        }
      }
    }
  };

  const edittab = async (val) => {
    setAddressshow(false);
    setAddressUpdate(true);
    resetAddress();
    setAddress_id(val.address_id);
    if (address_id == val.address_id || address_id == "") {
      setAddressUpdate(!addressUpdate);
    } else {
      setAddressUpdate(true);
    }
    setAdd_firstname(val.first_name);
    setAdd_lastname(val.last_name);
    setAdd_phone(val.phone);
    setAdd_email(val.email);
    setAdd_address1(val.address1);
    setAdd_address2(val.address2);
    setAdd_city(val.city);
    setAdd_state(val.state);
    setAdd_postalcode(val.zip);
    setAdd_company(val.company);
    setAdd_country("United States");
    setAdd_name(val.name);
    setAdd_ship_default(val.is_shipping_default != "0" ? true : false);
    setAdd_bill_default(val.is_billing_default != "0" ? true : false);
    // setAdd_home(val.address_type == "home" ? true : false)
    // setAdd_office(val.address_type == "office" ? true : false)
    setAdd_type(val.address_type);
  };

  const deleteAdd = async (val) => {
    dispatch(loader(true));
    const data = {
      user_id: user?.user_id ? user?.user_id : 0,
      token: user?.token,
      address_id: val.address_id,
    };
    const response = await postData("/user/delete_address", data);
    if (response.data.status == 1) {
      dispatch(loader(false));
      //getaddress();
      dispatch(addressData(response.data.data?.address));
      console.log(response.data.message, { autoClose: 3000 });
    } else {
      dispatch(loader(false));
      console.log(response.data.message, { autoClose: 3000 });
    }
  };
  return (
    <>
      <div className="rt_cart_section_bg">
        <Container className="rt_custome_container">
          <Row className="w-100 m-auto">
            <Col>
              <div className="rt_cart_banner_content">
                <h1>CheckOut</h1>
                <ul>
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <BsArrowRight />
                  </li>
                  <li>
                    <NavLink to="#">CheckOut</NavLink>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="rt_cart_content ">
        <Container className="rt_custome_container">
          <Row className="w-100 m-auto">
            <Col xs={12} md={8} lg={8} xl={9}>
              <div className="rt_checkout_content_left mb-5">
                <div className="rt_myaccount-content ">
                  <h5>Shipping Address</h5>
                  <Row>
                    {addresslist?.map((val) => (
                      <Col sm={4} xs={12}>
                        <div className="rt_add_box rt_checkout_box">
                          <div className="rt_icn451 rt_icn_checkout">
                            <IconButton
                              className="myacc_edit"
                              aria-label="edit"
                              component="span"
                            >
                              <EditIcon onClick={() => edittab(val)} />
                            </IconButton>
                            <IconButton
                              className="myacc_remove"
                              aria-label="delete"
                              component="span"
                            >
                              <DeleteIcon
                                onClick={() => {
                                  deleteAdd(val);
                                  setAddressUpdate(false);
                                  setAddressshow(false);
                                }}
                              />
                            </IconButton>
                          </div>

                          <address>
                            <p className="rt_checkout_name_text">
                              <strong>
                                Name:{val.first_name} {val.last_name}{" "}
                              </strong>{" "}
                            </p>
                            <p>
                              <strong>Address: </strong>{" "}
                            </p>
                            <p>
                              <span>
                                {val.address1}, {val.city}, {val.state},{" "}
                                {val.zip}
                              </span>
                            </p>
                            <p>
                              <strong>Mobile Number: </strong>
                            </p>
                            <p>
                              <span>{val.phone}</span>
                            </p>
                            <Divider />
                            {/* {val.is_billing_default != "0" || val.is_shipping_default != "0" || val.address_type == "Home" || val.address_type == "Office" ?
                                                                        <Divider /> : ''} */}
                            <Row>
                              <Col xl={6} lg={6}>
                                <div className="rt_checkbox_5243">
                                  {/* {val.address_type == "Home"  ? <Button className="rt_address_btn">Home</Button> : ""}
                                                        {val.address_type == "Office"  ? <Button className="rt_address_btn">Office</Button> : ""}
                                                        {val.is_shipping_default == "1" ? <Button className="rt_address_btn">Default Billing</Button> : ""}
                                                        {val.is_billing_default == "1" ? <Button className="rt_address_btn">Default Shipping</Button> : ""} */}
                                  <Form.Check
                                    type="checkbox"
                                    id="1"
                                    label="shipping address"
                                    checked={
                                      shippingaddress == val.address_id
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      setShippingaddress(val.address_id)
                                    }
                                  />
                                </div>
                              </Col>
                              <Col xl={6} lg={6}>
                                <div className="rt_checkbox_5243 float-right">
                                  <Form.Check
                                    type="checkbox"
                                    id="1"
                                    label="billing address"
                                    checked={
                                      billingaddress == val.address_id
                                        ? true
                                        : false
                                    }
                                    onChange={() =>
                                      setBillingaddress(val.address_id)
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                          </address>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  {/* <Button className="rt_fill_button" onClick={() => setAddressshow(!addressshow)}>
                                            <AccountCircleIcon /> Add New Address
                                        </Button> */}
                  {addressUpdate ? (
                    ""
                  ) : (
                    <Button
                      className="rt_fill_button rt_add_new"
                      onClick={() => {
                        setAddressshow(!addressshow);
                        setAddressUpdate(false);
                        resetAddress();
                      }}
                    >
                      {addressshow ? (
                        <>
                          {" "}
                          Add New Address <RemoveCircleIcon />{" "}
                        </>
                      ) : (
                        <>
                          {" "}
                          Add New Address <AddCircleIcon />
                        </>
                      )}
                    </Button>
                  )}
                </div>
                {addressshow || addressUpdate ? (
                  <div className="rt_myaccount_input rt_myaccount_content_left">
                    <Form
                      onSubmit={(e) => {
                        addressshow
                          ? handleSubmit(e, formheading)
                          : editaddress(e, formheading);
                      }}
                    >
                      <Row>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">
                            First Name
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter First Name"
                              type="text"
                              aria-label="Enter First Name"
                              aria-describedby="basic-addon1"
                              value={add_firstname}
                              onChange={(e) => setAdd_firstname(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">Last Name</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Last Name"
                              type="text"
                              aria-label="Enter Last Name"
                              aria-describedby="basic-addon1"
                              value={add_lastname}
                              onChange={(e) => setAdd_lastname(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">
                            Phone Number
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Phone Number"
                              type="number"
                              aria-label="Enter Phone Number"
                              aria-describedby="basic-addon1"
                              value={add_phone}
                              onChange={(e) => setAdd_phone(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">
                            Landmark (Optional)
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Your Landmark"
                              type="text"
                              aria-label="Enter Your Landmark"
                              value={add_landmark}
                              onChange={(e) => setAdd_landmark(e.target.value)}
                              aria-describedby="basic-addon1"
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">
                            Address Line 1
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Your Address Line 1"
                              type="text"
                              aria-label="Enter Your Address Line 1"
                              aria-describedby="basic-addon1"
                              value={add_address1}
                              onChange={(e) => setAdd_address1(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">
                            Address Line 2 (Optional)
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Your Address Line 2"
                              type="text"
                              aria-label="Enter Your Address Line 2"
                              aria-describedby="basic-addon1"
                              value={add_address2}
                              onChange={(e) => setAdd_address2(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">Country</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Country"
                              type="text"
                              aria-label="Enter Country"
                              aria-describedby="basic-addon1"
                              value={add_country}
                              onChange={(e) => setAdd_country(e.target.value)}
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">State</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter State"
                              type="text"
                              aria-label="Enter Your State"
                              aria-describedby="basic-addon1"
                              value={add_state}
                              onChange={(e) => setAdd_state(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">City</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter City"
                              type="text"
                              aria-label="Enter City"
                              aria-describedby="basic-addon1"
                              value={add_city}
                              onChange={(e) => setAdd_city(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">Zip Code</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Zip Code"
                              type="number"
                              aria-label="Enter Zip Code"
                              aria-describedby="basic-addon1"
                              value={add_postalcode}
                              onChange={(e) =>
                                setAdd_postalcode(e.target.value)
                              }
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Col xs={12} className="p-0">
                            <Form.Check
                              aria-label="option 1"
                              label="Default Shipping Address"
                              checked={add_ship_default}
                              onChange={() => {
                                setAdd_ship_default(true);
                              }}
                            />
                          </Col>
                          <Col xs={12} className="p-0">
                            <Form.Check
                              aria-label="option 1"
                              label="Default Billing Address"
                              checked={add_bill_default}
                              onChange={() => {
                                setAdd_bill_default(true);
                              }}
                            />
                          </Col>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Col xs={12} className="p-0">
                            <Form.Check
                              aria-label="option 1"
                              label="Home"
                              checked={add_type == "Home" || add_type == ""}
                              onChange={() => setAdd_type("Home")}
                            />
                          </Col>
                          <Col xs={12} className="p-0">
                            <Form.Check
                              aria-label="option 1"
                              label="Office"
                              checked={add_type == "Office"}
                              onChange={() => setAdd_type("Office")}
                            />
                          </Col>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button type="submit" className="rt_checkout_btn">
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </Col>
            <Col xs={12} md={4} lg={4} xl={3}>
              <div className="rt_cart_content_right">
                <h2>Order Summary</h2>
                <ul className="rt_payment_details">
                  <li>
                    <p>Subtotal</p>
                  </li>
                  <li>
                    <p>${cartlist?.subtotal}</p>
                  </li>
                </ul>
                <ul className="rt_payment_details">
                  <li>
                    <p>Shipping Charges</p>
                  </li>
                  <li>
                    <p>${checkoutdata?.shipping_cost}</p>
                  </li>
                </ul>
                {/* <ul className='rt_payment_details'>
                                    <li><p>Coupon Discount</p></li>
                                    <li><p>$0</p></li>
                                </ul> */}
                <ul className="rt_payment_details">
                  <li>
                    <p>Sales tax Estimate</p>
                  </li>
                  <li>
                    <p>${cartlist?.tax}</p>
                  </li>
                </ul>
                <ul className="rt_payment_total">
                  <li>
                    <p>
                      <strong>Total Amount</strong>
                    </p>
                  </li>
                  <li>
                    <p>
                      <strong>${cartlist?.total_price}</strong>
                    </p>
                  </li>
                </ul>
                {paypalpopup ? (
                  <Paypal id="paypalbutton" data={paypalreq} />
                ) : (
                  <Button
                    className="rt_checkout_btn"
                    onClick={(e) => checkoutbutton()}
                  >
                    proceed to checkout
                  </Button>
                )}
              </div>
              <div className="rt_cart_bottom">
                <h2>Need Help?</h2>
                <p>Shipping</p>
                <p>Privacy Policy</p>
                {/* <h2>Returns</h2>
                <p>
                  Our policy includes returning an item to any of our retail
                  stores.
                </p> */}
              </div>
            </Col>
          </Row>

          {!user?.isLogin ? (
            <>
              {" "}
              <Row className="w-100 m-auto">
                <Col xs={12} md={8} lg={8} xl={9}>
                  <div className="rt_checkout_content_left">
                    <Form>
                      <Row className="w-100 m-auto">
                        <Col xs={12} md={6}>
                          <Form.Label htmlFor="basic-url">
                            First Name
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              id="basic-url"
                              type="text"
                              aria-describedby="basic-addon3"
                              required
                              value={sfirst_name}
                              onChange={(e) => setSfirst_name(e.target.value)}
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Label htmlFor="basic-url">Last Name</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              id="basic-url"
                              type="text"
                              aria-describedby="basic-addon3"
                              value={slast_name}
                              onChange={(e) => setSlast_name(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Label htmlFor="basic-url">
                            Email Address
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              id="basic-url"
                              type="email"
                              aria-describedby="basic-addon3"
                              value={semail}
                              onChange={(e) => setSemail(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Label htmlFor="basic-url">
                            Phone Number
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              id="basic-url"
                              type="number"
                              aria-describedby="basic-addon3"
                              value={sphone}
                              onChange={(e) => setSphone(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12}>
                          <Form.Label htmlFor="basic-url">
                            Address Line 1
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              id="basic-url"
                              type="text"
                              aria-describedby="basic-addon3"
                              value={sadd1}
                              onChange={(e) => setSadd1(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12}>
                          <Form.Label htmlFor="basic-url">
                            Address Line 2 (Optional)
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              id="basic-url"
                              type="text"
                              aria-describedby="basic-addon3"
                              value={sadd2}
                              onChange={(e) => setSadd2(e.target.value)}
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Label htmlFor="basic-url">Country</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              id="basic-url"
                              type="text"
                              aria-describedby="basic-addon3"
                              required
                              value={scountry}
                              onChange={(e) => setScountry(e.target.value)}
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Label htmlFor="basic-url">State</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              id="basic-url"
                              type="text"
                              aria-describedby="basic-addon3"
                              required
                              value={sstate}
                              onChange={(e) => setSstate(e.target.value)}
                            />
                          </InputGroup>
                        </Col>

                        <Col xs={12} md={6}>
                          <Form.Label htmlFor="basic-url">City</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              id="basic-url"
                              type="text"
                              aria-describedby="basic-addon3"
                              value={scity}
                              onChange={(e) => setScity(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Label htmlFor="basic-url">Zip Code</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              id="basic-url"
                              type="number"
                              aria-describedby="basic-addon3"
                              value={szip}
                              onChange={(e) => setSzip(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>

                        <Col xs={12} sm={6}>
                          <Col xs={12} className="p-0">
                            <Form.Check
                              aria-label="option 1"
                              label="Home"
                              checked={sadd_type == "Home" || sadd_type == ""}
                              onChange={() => setSadd_type("Home")}
                            />
                          </Col>
                          <Col xs={12} className="p-0">
                            <Form.Check
                              aria-label="option 1"
                              label="Office"
                              checked={sadd_type == "Office"}
                              onChange={() => setSadd_type("Office")}
                            />
                          </Col>
                        </Col>

                        <Col xs={12}>
                          <Form.Check
                            aria-label="option 1"
                            label="Billing address is same as shipping address"
                            checked={sameasshipping}
                            onClick={() => setSameasshipping(!sameasshipping)}
                          />
                        </Col>
                        {sameasshipping ? (
                          <Col xs={12} md={{ span: 4, offset: 4 }}>
                            <div className="paypalbutton">
                              {paypalpopup ? (
                                <Paypal id="paypalbutton" data={paypalreq} />
                              ) : (
                                <Button
                                  type="submit"
                                  className="rt_checkout_btn m-0"
                                  onClick={() => checkoutbutton()}
                                >
                                  continue
                                </Button>
                              )}
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Row>
              {!sameasshipping ? (
                <Row className="w-100 m-auto mt-4">
                  <Col xs={12} md={8} lg={8} xl={9}>
                    <div className="rt_checkout_content_left rt_chekout_new">
                      <Form>
                        <Row className="w-100 m-auto">
                          <Col xs={12} md={6}>
                            <Form.Label htmlFor="basic-url">
                              First Name
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="basic-url"
                                type="text"
                                aria-describedby="basic-addon3"
                                required
                                value={bfirst_name}
                                onChange={(e) => setBfirst_name(e.target.value)}
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Label htmlFor="basic-url">
                              Last Name
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="basic-url"
                                type="text"
                                aria-describedby="basic-addon3"
                                value={blast_name}
                                onChange={(e) => setBlast_name(e.target.value)}
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Label htmlFor="basic-url">
                              Phone Number
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="basic-url"
                                type="number"
                                aria-describedby="basic-addon3"
                                value={bphone}
                                onChange={(e) => setBphone(e.target.value)}
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Label htmlFor="basic-url">Email</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="basic-url"
                                type="text"
                                aria-describedby="basic-addon3"
                                value={badd1}
                                onChange={(e) => setBemail(e.target.value)}
                                required
                              />
                            </InputGroup>
                          </Col>

                          <Col xs={12} md={6}>
                            <Form.Label htmlFor="basic-url">
                              Address Line 1
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="basic-url"
                                type="text"
                                aria-describedby="basic-addon3"
                                value={badd1}
                                onChange={(e) => setBadd1(e.target.value)}
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Label htmlFor="basic-url">
                              Address Line 2 (Optional)
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="basic-url"
                                type="text"
                                aria-describedby="basic-addon3"
                                value={badd2}
                                onChange={(e) => setBadd2(e.target.value)}
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Label htmlFor="basic-url">Country</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="basic-url"
                                type="text"
                                aria-describedby="basic-addon3"
                                required
                                value={scountry}
                                onChange={(e) => setBcountry(e.target.value)}
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Label htmlFor="basic-url">State</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="basic-url"
                                type="text"
                                aria-describedby="basic-addon3"
                                required
                                value={sstate}
                                onChange={(e) => setBstate(e.target.value)}
                              />
                            </InputGroup>
                          </Col>

                          <Col xs={12} md={6}>
                            <Form.Label htmlFor="basic-url">City</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="basic-url"
                                type="text"
                                aria-describedby="basic-addon3"
                                value={scity}
                                onChange={(e) => setBcity(e.target.value)}
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Label htmlFor="basic-url">
                              Zip Code
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                id="basic-url"
                                type="number"
                                aria-describedby="basic-addon3"
                                value={bzip}
                                onChange={(e) => setBzip(e.target.value)}
                                required
                              />
                            </InputGroup>
                          </Col>

                          <Col xs={12} sm={6}>
                            <Col xs={12} className="p-0">
                              <Form.Check
                                aria-label="option 1"
                                label="Home"
                                checked={badd_type == "Home" || badd_type == ""}
                                onChange={() => setBadd_type("Home")}
                              />
                            </Col>
                            <Col xs={12} className="p-0">
                              <Form.Check
                                aria-label="option 1"
                                label="Office"
                                checked={badd_type == "Office"}
                                onChange={() => setBadd_type("Office")}
                              />
                            </Col>
                          </Col>

                          <Col xs={12} md={{ span: 4, offset: 4 }}>
                            <div className="paypalbutton">
                              {paypalpopup ? (
                                <Paypal id="paypalbutton" data={paypalreq} />
                              ) : (
                                <Button
                                  type="submit"
                                  className="rt_checkout_btn m-0"
                                  onClick={() => checkoutbutton()}
                                >
                                  continue
                                </Button>
                              )}
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Container>
      </div>
    </>
  );
};

export default Checkout;
