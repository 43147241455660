import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Tabs,
  Tab,
  Box,
  withStyles,
  TableCell,
  TableRow,
  TableContainer,
  TableHead,
  TableBody,
  Paper,
  Table,
} from "@material-ui/core";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  InputGroup,
} from "react-bootstrap";
import orderImage from "../Assets/Images/about_img.png";
import "../Assets/Css/Myaccount.css";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { BiLogOutCircle } from "react-icons/bi";
import RoomIcon from "@mui/icons-material/Room";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { BsArrowRight } from "react-icons/bs";
import LockIcon from "@mui/icons-material/Lock";
import PersonIcon from "@mui/icons-material/Person";
import { BsStarFill } from "react-icons/bs";
import productImage from "../Assets/Images/productList/product.png";
import { AiFillHeart } from "react-icons/ai";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import axios from "axios";
import { cartCountvalue, cartData, cartDetails } from "../Reducer/cartReducer";
import swal from "sweetalert";
import { toast } from "react-toastify";
import { base_url, postData, postHeader } from "../Helpers/request";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { orderData, userData } from "../Reducer/userReducers";
import { store } from "../Store/store";
import { addressData } from "../Reducer/addressReducer";
import { loader, sessionId } from "../Reducer/homeReducer";
import { cartListcount, wishListcount } from "../Reducer/productReducers";
import { whishlistlength, wishlistData } from "../Reducer/wishlistReducer";
import storage from "redux-persist/lib/storage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "auto",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#f5f8fe",
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(order_id, product_name, product_detail, price, date) {
  return { order_id, product_name, product_detail, price, date };
}

const rows = [
  createData(
    1,
    "Ria Geometric Washable Rug",
    "Handcrafted in the style of a chunky knit sweater...",
    59.99,
    "20 June 2022"
  ),
  createData(
    2,
    "Ria Geometric Washable Rug",
    "Handcrafted in the style of a chunky knit sweater...",
    59.99,
    "20 June 2022"
  ),
];

const Myaccount = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [country, setCountry] = useState("");
  const [state12, setState12] = useState("");
  const [shippingaddress, setShippingAddress] = useState("");
  const [billingaddress, setBillingAddress] = useState("");
  const history = useNavigate();
  //const [orderdata, setOrderdata] = React.useState([]);
  const [formheading, setFormheading] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [birthdate, setBirthdate] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [add_firstname, setAdd_firstname] = React.useState("");
  const [add_lastname, setAdd_lastname] = React.useState("");
  const [add_phone, setAdd_phone] = React.useState("");
  const [add_email, setAdd_email] = React.useState("");
  const [add_address1, setAdd_address1] = React.useState("");
  const [add_address2, setAdd_address2] = React.useState("");
  const [add_city, setAdd_city] = React.useState("");
  const [add_state, setAdd_state] = React.useState("");
  const [add_postalcode, setAdd_postalcode] = React.useState("");
  const [add_company, setAdd_company] = React.useState("");
  const [add_country, setAdd_country] = React.useState("United States");
  const [add_name, setAdd_name] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [newpassword, setNewpassword] = React.useState("");
  const [confirmpassword, setConfirmpassword] = React.useState("");
  const [addressUpdate, setAddressUpdate] = useState(false);
  const [addressshow, setAddressshow] = useState(false);
  const [address_id, setAddress_id] = useState("");
  const [add_landmark, setAdd_landmark] = useState("");
  const [add_ship_default, setAdd_ship_default] = useState(false);
  const [add_bill_default, setAdd_bill_default] = useState(false);
  const [add_type, setAdd_type] = useState("Home");
  //const [wishlistdata, setWishlistdata] = useState([]);
  const dispatch = useDispatch();
  //const wishlistlengthvalue = useSelector(state => state.persistedReducer.wish.whishlistlength)
  const wishlistdata = useSelector(
    (state) => state.persistedReducer.wish.wishlistData
  );
  const addresslist = useSelector(
    (state) => state.persistedReducer.address.addressData
  );
  const user = useSelector((state) => state.persistedReducer.user.userData);
  const orderdata = useSelector(
    (state) => state.persistedReducer.user.orderData
  );
  const sessionid = useSelector(
    (state) => state.persistedReducer.home?.sessionId
  );
  useEffect(() => {
    wishlist();
    getaddress();
    orderlist();
    setFirstname(user.first_name);
    setLastname(user.last_name);
    setPhone(user.phone);
    setEmail(user.email);
  }, []);
  // useEffect(() => {
  //     setValue(props.match.params.value);
  // }, [props.match.params.value])
  //get user info
  const userinfo = async () => {
    dispatch(loader(true));
    const data = {
      user_id: user?.user_id ? user?.user_id : 0,
      token: user?.token,
    };
    const response = await postData("/user/GetUserDetail", data);
    if (response.data.status == 1) {
      dispatch(loader(false));
      setFirstname(response.data.data?.first_name);
      setLastname(response.data.data?.last_name);
      setPhone(response.data.data?.phone);
      //setCompany(response.data.data?.address?.company);
      setEmail(response.data.data?.email);
      //setBirthdate(moment(new Date(response.data.data?.address?.DOB)).format("YYYY-MM-DD"));
    } else {
      dispatch(loader(false));
    }
  };
  // submiot user details or update user
  const submitAccount = async (e) => {
    e.preventDefault();
    dispatch(loader(true));
    const data = {
      user_id: user?.user_id ? user?.user_id : 0,
      first_name: firstname,
      last_name: lastname,
      email,
      phone,
      company: "",
      DOB: "",
      token: user?.token,
    };
    const response = await postData("/user/update_user", data);
    if (response.data.status == 1) {
      dispatch(loader(false));
      var users = {
        token: user.token,
        user_id: response.data.data.user_id,
        email: response.data.data.email,
        first_name: response.data.data.first_name,
        last_name: response.data.data.last_name,
        phone: response.data.data.phone,
        isLogin: true,
      };
      dispatch(userData(users));
      return swal(response.data.message, "", "success");
    } else {
      dispatch(loader(false));
      return swal(response.data.message, "", "error");
    }
  };
  // get order details
  const orderlist = async () => {
    const data = {
      user_id: user?.user_id ? user?.user_id : 0,
      token: user?.token,
    };
    const response = await postData("/order/enquiry_list", data);
    if (response.data.status == 1) {
      dispatch(orderData(response.data.data));
    } else {
      console.log(response.data.message)
    }
  };

  // change password
  const changepass = async (e) => {
    e.preventDefault();
    dispatch(loader(true));
    if (newpassword == confirmpassword) {
      const data = {
        user_id: user?.user_id ? user?.user_id : 0,
        password: newpassword,
        old_password: password,
        token: user?.token,
      };
      const response = await postData("/user/password_reset", data);

      if (response.data.status == 1) {
        dispatch(loader(false));
        setNewpassword("");
        setConfirmpassword("");
        setPassword("");
        return swal(response.data.message, "", "success");
      } else {
        dispatch(loader(false));
        return swal(response.data.message, "", "error");
      }
    } else {
      dispatch(loader(false));
      setNewpassword("");
      setConfirmpassword("");
      return swal("Password & confirm password do not match!", "", "error");
    }
  };

  // delete address
  const deleteAdd = async (val) => {
    dispatch(loader(true));
    const data = {
      user_id: user?.user_id ? user?.user_id : 0,
      token: user?.token,
      address_id: val.address_id,
    };
    const response = await postData("/user/delete_address", data);
    if (response.data.status == 1) {
      dispatch(loader(false));
      //getaddress();
      dispatch(addressData(response.data.data?.address));
      toast.success(response.data.message, { autoClose: 3000 });
    } else {
      dispatch(loader(false));
    }
  };

  // get data in edit address form
  const edittab = async (val) => {
    setAddressshow(false);
    setAddressUpdate(true);
    resetAddress();
    setAddress_id(val.address_id);
    if (address_id == val.address_id || address_id == "") {
      setAddressUpdate(!addressUpdate);
    } else {
      setAddressUpdate(true);
    }
    setAdd_firstname(val.first_name);
    setAdd_lastname(val.last_name);
    setAdd_phone(val.phone);
    setAdd_email(val.email);
    setAdd_address1(val.address1);
    setAdd_address2(val.address2);
    setAdd_city(val.city);
    setAdd_state(val.state);
    setAdd_postalcode(val.zip);
    setAdd_company(val.company);
    setAdd_country("United States");
    setAdd_name(val.name);
    setAdd_ship_default(val.is_shipping_default != "0" ? true : false);
    setAdd_bill_default(val.is_billing_default != "0" ? true : false);
    // setAdd_home(val.address_type == "home" ? true : false)
    // setAdd_office(val.address_type == "office" ? true : false)
    setAdd_type(val.address_type);
  };

  // edit address
  const editaddress = async (e, type) => {
    e.preventDefault();
    dispatch(loader(true));
    setAddressUpdate(false);
    const data = {
      address_id: address_id,
      user_id: user?.user_id ? user?.user_id : 0,
      first_name: add_firstname,
      last_name: add_lastname,
      phone: add_phone,
      email: add_email,
      address1: add_address1,
      address2: add_address2,
      city: add_city,
      state: add_state,
      zip: add_postalcode,
      company: add_company,
      country: add_country,
      name: add_name,
      token: user?.token,
      is_shipping_default: add_ship_default ? 1 : 0,
      is_billing_default: add_bill_default ? 1 : 0,
      address_type: add_type,
    };
    const response = await postData("/user/update_address", data);
    if (response.data.status == 1) {
      dispatch(loader(false));
      //getaddress();
      dispatch(addressData(response.data.data?.address));
      toast.success(response.data.message, { autoClose: 3000 });
    } else {
      dispatch(loader(false));
      toast.error(response.data.message, { autoClose: 3000 });
    }
    resetAddress();
  };

  // update address
  const handleSubmit = async (e, type) => {
    e.preventDefault();
    dispatch(loader(true));
    const data = {
      user_id: user?.user_id ? user?.user_id : 0,
      first_name: add_firstname,
      last_name: add_lastname,
      phone: add_phone,
      email: add_email,
      address1: add_address1,
      address2: add_address2,
      city: add_city,
      state: add_state,
      zip: add_postalcode,
      company: add_company,
      country: add_country,
      name: add_name,
      token: user?.token,
      is_billing_default: add_bill_default ? 1 : 0,
      is_shipping_default: add_ship_default ? 1 : 0,
      address_type: add_type,
    };
    const response = await postData("/user/add_user_address", data);
    if (response.data.status == 1) {
      dispatch(loader(false));
      setAddressshow(false);
      //getaddress()
      dispatch(addressData(response.data.data?.address));
      toast.success(response.data.message, { autoClose: 3000 });
    } else {
      dispatch(loader(false));
      toast.error(response.data.message, { autoClose: 3000 });
    }
    resetAddress();
  };

  // reset addresss
  const resetAddress = () => {
    setFormheading("");
    setAdd_firstname("");
    setAdd_lastname("");
    setAdd_phone("");
    setAdd_email("");
    setAdd_address1("");
    setAdd_address2("");
    setAdd_city("");
    setAdd_state("");
    setAdd_postalcode("");
    setAdd_company("");
    setAdd_country("United States");
    setAdd_name("");
    setAdd_ship_default(false);
    setAdd_bill_default(false);
    setAdd_type("");
  };

  // get wishlist
  const wishlist = async () => {
    const data = {
      user_id: user?.user_id ? user?.user_id : 0,
      session_id: sessionid ? sessionid : "",
      token: user?.token,
    };
    const res = await postData("/order/wish_list", data);

    if (res.data.status == 1) {
      dispatch(wishlistData(res.data.data));
      toast.success(res.message, { autoClose: 3000 });
    } else {
      toast.error(res.message, { autoClose: 3000 });
    }
  };

  // delete wishlist items
  const removewishlist = async (product_id, type) => {
    const data = {
      user_id: user?.user_id ? user?.user_id : 0,
      session_id: localStorage.getItem("rt-session-id")
        ? localStorage.getItem("rt-session-id")
        : "",
      product_id: product_id,
      type: type,
      token: user?.token,
    };
    const res = await postData("/order/add_to_wishlist", data);

    if (res.data.status == 1) {
      wishlist();
      dispatch(whishlistlength(res.data.data.total_count));
      toast.success(res.message, { autoClose: 3000 });
    } else {
      toast.error(res.message, { autoClose: 3000 });
    }
  };

  // get address list
  const getaddress = async () => {
    var data = {
      user_id: user?.user_id ? user?.user_id : 0,
      token: user?.token,
    };
    const response = await postData("/user/address_list", data);
    if (response.data.status == 1) {
      dispatch(addressData(response.data.data?.address));
    }
  };
  //logout
  const logout = () => {
    dispatch(userData([]));
    dispatch(addressData([]));
    dispatch(whishlistlength(""));
    dispatch(wishlistData([]));
    dispatch(cartCountvalue(""));
    dispatch(cartData([]));
    dispatch(sessionId(''))
    storage.removeItem("persist:session");
    history("/");
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChange1 = (event) => {
    setCountry(event.target.value);
  };
  const handleChange2 = (event) => {
    setState12(event.target.value);
  };
  const handleChange3 = (event) => {
    setShippingAddress({ [event.target.name]: event.target.checked });
  };
  const handleChange4 = (event) => {
    setBillingAddress({ [event.target.name]: event.target.checked });
  };
  return (
    <>
      <div className="rt_cart_section_bg">
        <Container className="rt_custome_container">
          <Row className="w-100 m-auto">
            <Col>
              <div className="rt_cart_banner_content">
                <h1>My Account</h1>
                <ul>
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <BsArrowRight />
                  </li>
                  <li>
                    <NavLink to="#">My Account</NavLink>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="rt_myaccount_mobile d-block d-lg-none">
        <Container className="rt_custome_container">
          <div className={classes.root}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={classes.heading}>
                  <PersonIcon /> Account
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="rt_myaccount-content">
                    <h5>Account</h5>
                    <p>
                      Hello <strong>{user?.first_name}</strong>, welcome to your
                      account
                    </p>
                    <p className="mb-0">
                      From your account dashboard. You can easily check &amp;
                      view your recent orders, manage your shipping and billing
                      addresses and edit your password and account details.
                    </p>
                  </div>
                  <div className="rt_myaccount_input rt_myaccount_content_left">
                    <Form onSubmit={submitAccount}>
                      <Row>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">
                            First Name
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter First Name"
                              aria-label="Enter First Name"
                              type="text"
                              aria-describedby="basic-addon1"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">Last Name</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Last Name"
                              aria-label="Enter Last Name"
                              type="text"
                              aria-describedby="basic-addon1"
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">Email</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Your Email"
                              aria-label="Enter Your Email"
                              type="email"
                              aria-describedby="basic-addon1"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">
                            Phone Number
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Phone Number"
                              aria-label="Enter Phone Number"
                              type="number"
                              aria-describedby="basic-addon1"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button type="submit" className="rt_checkout_btn">
                            Update
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
              >
                <Typography className={classes.heading}>
                  <LockIcon /> Change Password
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="rt_myaccount-content">
                    <h5 className="m-0">Password Change</h5>
                  </div>
                  <div className="rt_myaccount_input rt_myaccount_content_left">
                    <Form onSubmit={changepass}>
                      <Row>
                        <Col xs={12}>
                          <Form.Label htmlFor="basic-url">
                            Current Password
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Current Password"
                              type="password"
                              aria-label="Enter Current Password"
                              aria-describedby="basic-addon1"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12}>
                          <Form.Label htmlFor="basic-url">
                            New Password
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter New Password"
                              type="password"
                              aria-label="Enter New Password"
                              aria-describedby="basic-addon1"
                              value={newpassword}
                              onChange={(e) => setNewpassword(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12}>
                          <Form.Label htmlFor="basic-url">
                            Confirm New Password
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Confirm New Password"
                              type="password"
                              aria-label="Enter Confirm New Password"
                              aria-describedby="basic-addon1"
                              value={confirmpassword}
                              onChange={(e) =>
                                setConfirmpassword(e.target.value)
                              }
                              required
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button type="submit" className="rt_checkout_btn">
                            Save Changes
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3a-content"
                id="panel3a-header"
              >
                <Typography className={classes.heading}>
                  <ShoppingCartIcon /> Order Inquiry
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="rt_myaccount-content">
                    <h5>Order Inquiry</h5>
                    {orderdata?.length ? (
                      ""
                    ) : (
                      <p className="text-center m-0">
                        You haven't placed an order with this account yet.For
                        help, please call us at <br />
                        <NavLink to="#" className="text-dark font-weight-bold">
                          {" "}
                          +1 (773) 696-9552
                        </NavLink>
                      </p>
                    )}
                    <p className="text-center m-o">
                      <NavLink to="/" className="text-dark font-weight-bold">
                        {" "}
                        Start Shopping <ChevronRightIcon />{" "}
                      </NavLink>
                    </p>
                  </div>
                  <div className="rt_order_table">
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>S.NO.</StyledTableCell>
                            <StyledTableCell>ORDER INQUIRY NO</StyledTableCell>
                            <StyledTableCell>PRICE</StyledTableCell>
                            <StyledTableCell>DATE</StyledTableCell>
                            <StyledTableCell>ACTION</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orderdata?.map((val, index) => (
                            <StyledTableRow>
                              <StyledTableCell component="th" scope="row">
                                {index + 1}
                              </StyledTableCell>
                              <StyledTableCell>{val?.id}</StyledTableCell>
                              <StyledTableCell>
                                {val?.total_amount}
                              </StyledTableCell>
                              <StyledTableCell>
                                {moment(new Date(val?.created_at)).format(
                                  "YYYY-MM-DD"
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                            
                                <Button className="rt_fill_button" >

                                <VisibilityIcon />
                                  
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4a-content"
                id="panel4a-header"
              >
                <Typography className={classes.heading}>
                  <RoomIcon /> Address
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="rt_myaccount-content">
                    <h5>Billing Address</h5>
                    <Row>
                      {addresslist?.map((val) => (
                        <Col sm={6}>
                          <div className="rt_add_box">
                            <address>
                              <p>
                                <strong>Name: </strong>{" "}
                              </p>
                              <p>
                                <span>
                                  {val.first_name} {val.last_name}
                                </span>
                              </p>
                              <p>
                                <strong>Address: </strong>{" "}
                              </p>
                              <p>
                                <span>
                                  {val.address1}, {val.city}, {val.state},{" "}
                                  {val.zip}
                                </span>
                              </p>
                              <p>
                                <strong>Mobile Number: </strong>
                              </p>
                              <p>
                                <span>{val.phone}</span>
                              </p>
                              <Divider />

                              {val.is_billing_default != "0" ? (
                                <Button className="rt_address_btn">Home</Button>
                              ) : (
                                ""
                              )}
                              {val.address_type != "office" ? (
                                <Button className="rt_address_btn">Home</Button>
                              ) : (
                                <Button className="rt_address_btn">
                                  Office
                                </Button>
                              )}
                              {val.is_shipping_default == "1" ? (
                                <Button className="rt_address_btn">
                                  Default Billing
                                </Button>
                              ) : (
                                ""
                              )}
                              {val.is_billing_default == "1" ? (
                                <Button className="rt_address_btn">
                                  Default Shipping
                                </Button>
                              ) : (
                                ""
                              )}

                              <IconButton
                                className="myacc_edit"
                                aria-label="edit"
                                component="span"
                                onClick={() => edittab(val)}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                className="myacc_remove"
                                aria-label="delete"
                                component="span"
                              >
                                <DeleteIcon
                                  onClick={() => {
                                    deleteAdd(val);
                                    setAddressUpdate(false);
                                    setAddressshow(false);
                                  }}
                                />
                              </IconButton>
                            </address>
                          </div>
                        </Col>
                      ))}
                    </Row>
                    {/* <Button className="rt_fill_button" onClick={() => setAddressshow(!addressshow)}>
                                        <AccountCircleIcon /> Add New Address
                                    </Button> */}
                    {addressUpdate ? (
                      ""
                    ) : (
                      <Button
                        className="rt_fill_button rt_add_new"
                        onClick={() => {
                          setAddressshow(!addressshow);
                          setAddressUpdate(false);
                          resetAddress();
                        }}
                      >
                        {addressshow ? (
                          <>
                            {" "}
                            Add New Address <RemoveCircleIcon />{" "}
                          </>
                        ) : (
                          <>
                            {" "}
                            Add New Address <AddCircleIcon />
                          </>
                        )}
                      </Button>
                    )}
                  </div>
                  {addressshow ? (
                    <div className="rt_myaccount_input rt_myaccount_content_left">
                      <Form onSubmit={(e) => handleSubmit(e, formheading)}>
                        <Row>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">
                              First Name
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter First Name"
                                type="text"
                                aria-label="Enter First Name"
                                aria-describedby="basic-addon1"
                                value={add_firstname}
                                onChange={(e) =>
                                  setAdd_firstname(e.target.value)
                                }
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">
                              Last Name
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Last Name"
                                type="text"
                                aria-label="Enter Last Name"
                                aria-describedby="basic-addon1"
                                value={add_lastname}
                                onChange={(e) =>
                                  setAdd_lastname(e.target.value)
                                }
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">
                              Phone Number
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Phone Number"
                                type="number"
                                aria-label="Enter Phone Number"
                                aria-describedby="basic-addon1"
                                value={add_phone}
                                onChange={(e) => setAdd_phone(e.target.value)}
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">
                              Landmark (Optional)
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Your Landmark"
                                type="text"
                                aria-label="Enter Your Landmark"
                                value={add_landmark}
                                onChange={(e) =>
                                  setAdd_landmark(e.target.value)
                                }
                                aria-describedby="basic-addon1"
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">
                              Address Line 1
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Your Address Line 1"
                                type="text"
                                aria-label="Enter Your Address Line 1"
                                aria-describedby="basic-addon1"
                                value={add_address1}
                                onChange={(e) =>
                                  setAdd_address1(e.target.value)
                                }
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">
                              Address Line 2 (Optional)
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Your Address Line 2"
                                type="text"
                                aria-label="Enter Your Address Line 2"
                                aria-describedby="basic-addon1"
                                value={add_address2}
                                onChange={(e) =>
                                  setAdd_address2(e.target.value)
                                }
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">Country</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Country"
                                type="text"
                                aria-label="Enter Country"
                                aria-describedby="basic-addon1"
                                value={add_country}
                                onChange={(e) => setAdd_country(e.target.value)}
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">State</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter State"
                                type="text"
                                aria-label="Enter Your State"
                                aria-describedby="basic-addon1"
                                value={add_state}
                                onChange={(e) => setAdd_state(e.target.value)}
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">City</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter City"
                                type="text"
                                aria-label="Enter City"
                                aria-describedby="basic-addon1"
                                value={add_city}
                                onChange={(e) => setAdd_city(e.target.value)}
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">
                              Zip Code
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Zip Code"
                                type="number"
                                aria-label="Enter Zip Code"
                                aria-describedby="basic-addon1"
                                value={add_postalcode}
                                onChange={(e) =>
                                  setAdd_postalcode(e.target.value)
                                }
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Col xs={12} className="p-0">
                              <Form.Check
                                aria-label="option 1"
                                label="Default Shipping Address"
                                checked={add_ship_default}
                                onChange={() => {
                                  setAdd_ship_default(!add_ship_default);
                                }}
                              />
                            </Col>
                            <Col xs={12} className="p-0">
                              <Form.Check
                                aria-label="option 1"
                                label="Default Billing Address"
                                checked={add_bill_default}
                                onChange={() => {
                                  setAdd_bill_default(!add_bill_default);
                                }}
                              />
                            </Col>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Col xs={12} className="p-0">
                              <Form.Check
                                aria-label="option 1"
                                label="Home"
                                checked={add_type == "Home" || add_type == ""}
                                onChange={() => setAdd_type("Home")}
                              />
                            </Col>
                            <Col xs={12} className="p-0">
                              <Form.Check
                                aria-label="option 1"
                                label="Office"
                                checked={add_type == "Office"}
                                onChange={() => setAdd_type("Office")}
                              />
                            </Col>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="submit" className="rt_checkout_btn">
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  ) : (
                    ""
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
            <NavLink
              to="/wishlist"
              onClick={() => window.scrollTo(0, 0)}
              className="rt_myacc_wish"
            >
              <Tab label="Wishlist" icon={<FavoriteIcon />} />
            </NavLink>
            <NavLink
              to="#"
              onClick={logout}
              className="rt_myacc_wish"
            >
              <Tab label="Logout" icon={<BiLogOutCircle className="rt_logout_icn" />} />
             
            </NavLink>
          </div>
        </Container>
      </div>
      <div className="rt_myaccount d-none d-lg-block">
        <Container className="rt_custome_container">
          <div className={classes.root}>
            <Row>
              <Col sm={4} md={3} lg={3} xl={3}>
                <Tabs
                  orientation="vertical"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  className={classes.tabs}
                >
                  <Tab
                    className="rt_side_tabs"
                    label="Account"
                    icon={<PersonIcon />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    className="rt_side_tabs"
                    label="Change Password"
                    icon={<LockIcon />}
                    {...a11yProps(1)}
                  />
                  <Tab
                    className="rt_side_tabs"
                    label="Order Inquiry"
                    icon={<ShoppingCartIcon />}
                    {...a11yProps(2)}
                  />
                  <Tab
                    className="rt_side_tabs"
                    label="Address"
                    icon={<RoomIcon />}
                    {...a11yProps(3)}
                  />
                  <Tab
                    className="rt_side_tabs"
                    label="Wishlist"
                    icon={<FavoriteIcon />}
                    {...a11yProps(4)}
                  />
                  <Tab
                    className="rt_side_tabs"
                    label="logout"
                    icon={<BiLogOutCircle className="rt_logout_icn" />}
                    onClick={logout}
                  />
                  {""}
                </Tabs>
              </Col>
              <Col sm={8} md={9} lg={9} xl={9}>
                <TabPanel className="rt_side_tabpanel" value={value} index={0}>
                  <div className="rt_myaccount-content">
                    <h5>Account</h5>
                    <p>
                      Hello <strong>{user?.first_name}</strong>, welcome to your
                      account
                    </p>
                    <p className="mb-0">
                      From your account dashboard. You can easily check &amp;
                      view your recent orders, manage your shipping and billing
                      addresses and edit your password and account details.
                    </p>
                  </div>
                  <div className="rt_myaccount_input rt_myaccount_content_left">
                    <Form onSubmit={submitAccount}>
                      <Row>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">
                            First Name
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter First Name"
                              aria-label="Enter First Name"
                              type="text"
                              aria-describedby="basic-addon1"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">Last Name</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Last Name"
                              aria-label="Enter Last Name"
                              type="text"
                              aria-describedby="basic-addon1"
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">Email</Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Your Email"
                              aria-label="Enter Your Email"
                              type="email"
                              aria-describedby="basic-addon1"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                          <Form.Label htmlFor="basic-url">
                            Phone Number
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Phone Number"
                              aria-label="Enter Phone Number"
                              type="number"
                              aria-describedby="basic-addon1"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button type="submit" className="rt_checkout_btn">
                            Update
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </TabPanel>
                <TabPanel className="rt_side_tabpanel" value={value} index={1}>
                  <div className="rt_myaccount-content">
                    <h5 className="m-0">Password Change</h5>
                  </div>
                  <div className="rt_myaccount_input rt_myaccount_content_left">
                    <Form onSubmit={changepass}>
                      <Row>
                        <Col xs={12}>
                          <Form.Label htmlFor="basic-url">
                            Current Password
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Current Password"
                              type="password"
                              aria-label="Enter Current Password"
                              aria-describedby="basic-addon1"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12}>
                          <Form.Label htmlFor="basic-url">
                            New Password
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter New Password"
                              type="password"
                              aria-label="Enter New Password"
                              aria-describedby="basic-addon1"
                              value={newpassword}
                              onChange={(e) => setNewpassword(e.target.value)}
                              required
                            />
                          </InputGroup>
                        </Col>
                        <Col xs={12}>
                          <Form.Label htmlFor="basic-url">
                            Confirm New Password
                          </Form.Label>
                          <InputGroup className="mb-3">
                            <Form.Control
                              placeholder="Enter Confirm New Password"
                              type="password"
                              aria-label="Enter Confirm New Password"
                              aria-describedby="basic-addon1"
                              value={confirmpassword}
                              onChange={(e) =>
                                setConfirmpassword(e.target.value)
                              }
                              required
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button type="submit" className="rt_checkout_btn">
                            Save Changes
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </TabPanel>
                <TabPanel className="rt_side_tabpanel" value={value} index={2}>
                  <div className="rt_myaccount-content">
                    <h5>Order Inquiry</h5>
                    <p className="text-center m-0">
                      You haven't placed an order with this account yet.For
                      help, please call us at <br />
                      <NavLink to="#" className="text-dark font-weight-bold">
                        {" "}
                        +1 (773) 696-9552
                      </NavLink>
                    </p>
                    <p className="text-center m-o">
                      <NavLink to="/" className="text-dark font-weight-bold">
                        {" "}
                        Start Shopping <ChevronRightIcon />{" "}
                      </NavLink>
                    </p>
                  </div>
                  <div className="rt_order_table">
                    <TableContainer component={Paper}>
                      <Table
                        className={classes.table}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>S.NO.</StyledTableCell>
                            <StyledTableCell>ORDER INQUIRY NO</StyledTableCell>
                            <StyledTableCell>PRICE</StyledTableCell>
                            <StyledTableCell>DATE</StyledTableCell>
                            <StyledTableCell>ACTION</StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {orderdata?.map((val, index) => (
                            <StyledTableRow>
                              <StyledTableCell component="th" scope="row">
                                {index + 1}
                              </StyledTableCell>
                              <StyledTableCell>{val?.id}</StyledTableCell>
                              <StyledTableCell>
                                {val?.total_amount}
                              </StyledTableCell>
                              <StyledTableCell>
                                {moment(new Date(val?.created_at)).format(
                                  "YYYY-MM-DD"
                                )}
                              </StyledTableCell>
                              <StyledTableCell>
                              <Link to={`/invoice/${val?.id}`} className="rt_fill_button btn btn-primary">
                                
                              <VisibilityIcon />
                                </Link>
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </TabPanel>
                <TabPanel className="rt_side_tabpanel" value={value} index={3}>
                  <div className="rt_myaccount-content">
                    <h5>Billing Address</h5>
                    <Row>
                      {addresslist?.map((val) => (
                        <Col sm={6}>
                          <div className="rt_add_box">
                            <address>
                              <p>
                                <strong>Name: </strong>{" "}
                              </p>
                              <p>
                                <span>
                                  {val.first_name} {val.last_name}
                                </span>
                              </p>
                              <p>
                                <strong>Address: </strong>{" "}
                              </p>
                              <p>
                                <span className="rt_add_box_address_text">
                                  {val.address1}, {val.city}, {val.state},{" "}
                                  {val.zip}
                                </span>
                              </p>
                              <p>
                                <strong>Mobile Number: </strong>
                              </p>
                              <p>
                                <span>{val.phone}</span>
                              </p>
                              <Divider />
                              {/* {val.is_billing_default != "0" || val.is_shipping_default != "0" || val.address_type == "Home" || val.address_type == "Office" ?
                                                                        : ''} */}
                              <Row>
                                <Col xl={8} lg={7} md={7}>
                                  <div className="rt_btn_5243">
                                    {val.address_type == "Home" ? (
                                      <Button className="rt_address_btn">
                                        Home
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    {val.address_type == "Office" ? (
                                      <Button className="rt_address_btn">
                                        Office
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    {val.is_shipping_default == "1" ? (
                                      <Button className="rt_address_btn">
                                        Default Billing
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                    {val.is_billing_default == "1" ? (
                                      <Button className="rt_address_btn">
                                        Default Shipping
                                      </Button>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </Col>
                                <Col xl={4} lg={5} md={5}>
                                  <div className="rt_icn451">
                                    <IconButton
                                      className="myacc_edit"
                                      aria-label="edit"
                                      component="span"
                                      onClick={() => edittab(val)}
                                    >
                                      <EditIcon />
                                    </IconButton>
                                    <IconButton
                                      className="myacc_remove"
                                      aria-label="delete"
                                      component="span"
                                    >
                                      <DeleteIcon
                                        onClick={() => {
                                          deleteAdd(val);
                                          setAddressUpdate(false);
                                          setAddressshow(false);
                                        }}
                                      />
                                    </IconButton>
                                  </div>
                                </Col>
                              </Row>
                            </address>
                          </div>
                        </Col>
                      ))}
                    </Row>
                    {/* <Button className="rt_fill_button" onClick={() => setAddressshow(!addressshow)}>
                                            <AccountCircleIcon /> Add New Address
                                        </Button> */}
                    {addressUpdate ? (
                      ""
                    ) : (
                      <Button
                        className="rt_fill_button rt_add_new"
                        onClick={() => {
                          setAddressshow(!addressshow);
                          setAddressUpdate(false);
                          resetAddress();
                        }}
                      >
                        {addressshow ? (
                          <>
                            {" "}
                            Add New Address <RemoveCircleIcon />{" "}
                          </>
                        ) : (
                          <>
                            {" "}
                            Add New Address <AddCircleIcon />
                          </>
                        )}
                      </Button>
                    )}
                  </div>
                  {addressshow || addressUpdate ? (
                    <div className="rt_myaccount_input rt_myaccount_content_left">
                      <Form
                        onSubmit={(e) => {
                          addressshow
                            ? handleSubmit(e, formheading)
                            : editaddress(e, formheading);
                        }}
                      >
                        <Row>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">
                              First Name
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter First Name"
                                type="text"
                                aria-label="Enter First Name"
                                aria-describedby="basic-addon1"
                                value={add_firstname}
                                onChange={(e) =>
                                  setAdd_firstname(e.target.value)
                                }
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">
                              Last Name
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Last Name"
                                type="text"
                                aria-label="Enter Last Name"
                                aria-describedby="basic-addon1"
                                value={add_lastname}
                                onChange={(e) =>
                                  setAdd_lastname(e.target.value)
                                }
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">
                              Phone Number
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Phone Number"
                                type="number"
                                aria-label="Enter Phone Number"
                                aria-describedby="basic-addon1"
                                value={add_phone}
                                onChange={(e) => setAdd_phone(e.target.value)}
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">
                              Landmark (Optional)
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Your Landmark"
                                type="text"
                                aria-label="Enter Your Landmark"
                                value={add_landmark}
                                onChange={(e) =>
                                  setAdd_landmark(e.target.value)
                                }
                                aria-describedby="basic-addon1"
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">
                              Address Line 1
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Your Address Line 1"
                                type="text"
                                aria-label="Enter Your Address Line 1"
                                aria-describedby="basic-addon1"
                                value={add_address1}
                                onChange={(e) =>
                                  setAdd_address1(e.target.value)
                                }
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">
                              Address Line 2 (Optional)
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Your Address Line 2"
                                type="text"
                                aria-label="Enter Your Address Line 2"
                                aria-describedby="basic-addon1"
                                value={add_address2}
                                onChange={(e) =>
                                  setAdd_address2(e.target.value)
                                }
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">Country</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Country"
                                type="text"
                                aria-label="Enter Country"
                                aria-describedby="basic-addon1"
                                value={add_country}
                                onChange={(e) => setAdd_country(e.target.value)}
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">State</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter State"
                                type="text"
                                aria-label="Enter Your State"
                                aria-describedby="basic-addon1"
                                value={add_state}
                                onChange={(e) => setAdd_state(e.target.value)}
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">City</Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter City"
                                type="text"
                                aria-label="Enter City"
                                aria-describedby="basic-addon1"
                                value={add_city}
                                onChange={(e) => setAdd_city(e.target.value)}
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Form.Label htmlFor="basic-url">
                              Zip Code
                            </Form.Label>
                            <InputGroup className="mb-3">
                              <Form.Control
                                placeholder="Enter Zip Code"
                                type="number"
                                aria-label="Enter Zip Code"
                                aria-describedby="basic-addon1"
                                value={add_postalcode}
                                onChange={(e) =>
                                  setAdd_postalcode(e.target.value)
                                }
                                required
                              />
                            </InputGroup>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Col xs={12} className="p-0">
                              <Form.Check
                                aria-label="option 1"
                                label="Default Shipping Address"
                                checked={add_ship_default}
                                onChange={() => {
                                  setAdd_ship_default(!add_ship_default);
                                }}
                              />
                            </Col>
                            <Col xs={12} className="p-0">
                              <Form.Check
                                aria-label="option 1"
                                label="Default Billing Address"
                                checked={add_bill_default}
                                onChange={() => {
                                  setAdd_bill_default(!add_bill_default);
                                }}
                              />
                            </Col>
                          </Col>
                          <Col xs={12} sm={6}>
                            <Col xs={12} className="p-0">
                              <Form.Check
                                aria-label="option 1"
                                label="Home"
                                checked={add_type == "Home" || add_type == ""}
                                onChange={() => setAdd_type("Home")}
                              />
                            </Col>
                            <Col xs={12} className="p-0">
                              <Form.Check
                                aria-label="option 1"
                                label="Office"
                                checked={add_type == "Office"}
                                onChange={() => setAdd_type("Office")}
                              />
                            </Col>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Button type="submit" className="rt_checkout_btn">
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  ) : (
                    ""
                  )}
                </TabPanel>
                <TabPanel className="rt_side_tabpanel" value={value} index={4}>
                  <div className="rt_myaccount-content">
                    <h5>Wishlist</h5>
                  </div>
                  {wishlistdata?.length ? (
                    <Row className="w-100 m-auto">
                      {wishlistdata?.map((res) => (
                        <Col lg={4} md={4} xs={12} sm={6}>
                          <div className="rt_product_list_content rt_myaccount_content">
                            <div className="rt_product_list_content_top position-relative">
                              <NavLink to={`/product-detail/${res.slug}`}>
                                <Image
                                  src={res.product_image}
                                  alt={res.product_name}
                                ></Image>
                              </NavLink>
                            </div>
                            <div className="rt_wishlist_icon rt_wishlist_icon_fill">
                              <span>
                                <AiFillHeart
                                  onClick={() =>
                                    removewishlist(
                                      res.product_id,
                                      res.product_type
                                    )
                                  }
                                />
                              </span>
                            </div>
                            <div className="rt_product_list_heading">
                              <NavLink to={`/product-detail/${res.slug}`}>
                                <h2>{res.product_name}</h2>
                              </NavLink>
                              <p>
                                {res.currency_icon}
                                {res.sale_price}
                              </p>
                            </div>
                          </div>
                        </Col>
                      ))}
                      {/* <Col lg={4} md={4} xs={12} sm={6}>
                                            <div className='rt_product_list_content rt_myaccount_content'>
                                                <div className='rt_product_list_content_top position-relative'>
                                                    <NavLink to="#">
                                                        <Image src={productImage} alt="Royal Trading Product"></Image>
                                                    </NavLink>                                                    
                                                </div>                                                
                                                <div className='rt_wishlist_icon rt_wishlist_icon_fill'>
                                                    <span><AiFillHeart /></span>
                                                </div>
                                                <div className='rt_product_list_heading'>
                                                    <NavLink to='#'>
                                                        <h2>Ria Geometric Washable Rug</h2>
                                                    </NavLink>
                                                    <p>$75</p>
                                                </div>
                                            </div>  
                                        </Col>
                                        <Col lg={4} md={4} xs={12} sm={6}>
                                            <div className='rt_product_list_content rt_myaccount_content'>
                                                <div className='rt_product_list_content_top position-relative'>
                                                    <NavLink to="#">
                                                        <Image src={productImage} alt="Royal Trading Product"></Image>
                                                    </NavLink>                                                    
                                                </div>                                                
                                                <div className='rt_wishlist_icon rt_wishlist_icon_fill'>
                                                    <span><AiFillHeart /></span>
                                                </div>
                                                <div className='rt_product_list_heading'>
                                                    <NavLink to='#'>
                                                        <h2>Ria Geometric Washable Rug</h2>
                                                    </NavLink>
                                                    <p>$75</p>
                                                </div>
                                            </div>  
                                        </Col> */}
                    </Row>
                  ) : (
                    <div>No wishlist items</div>
                  )}
                </TabPanel>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Myaccount;
