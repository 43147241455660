import React from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import '../Assets/Css/Static.css';
import { BsArrowRight } from "react-icons/bs";
import about_logo from '../Assets/Images/about_logo.png';
import about_image from '../Assets/Images/about_img.png';

const About = () => {
    return (
        <>
            <div className='rt_productlist_section_bg'>
                <Container className='rt_custome_container'>
                    <Row className='w-100 m-auto'>
                        <Col>
                            <div className='rt_productlist_banner_content'>
                                <h1>About</h1>
                                <ul>
                                    <li><NavLink to='/'>Home</NavLink></li>
                                    <li><BsArrowRight /></li>
                                    <li><NavLink to='#'>About</NavLink></li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className='rt_about_section'>
                <Container>
                    <Row className='w-100 m-auto align-items-center'>
                        <Col xs={12} sm={6}>
                            <div className="rt_about_content">
                                <h2>Royal Trading Inc </h2>
                                <p>was founded in 2001 specializing in bathroom products, area rugs and drapery hardware, our focus is to be your retail store one stop shop for bathroom, drapery hardware and area rugs, Royal Trading has gained its reputation by providing not only the product but the excellent customer service that stands behind it, retailers throughout the US and Caribbean has been relying on our products for more than 15 years.</p>
                                <p>We at Royal Trading believe that honesty and integrity is the key successes to any business, therefore we will not settle until every customer weather independent retailer or chain store is completely satisfied.</p>
                            </div>
                        </Col>
                        <Col xs={12} sm={6}>
                            <div className='rt_about_logo_bg'>
                                <Image src={about_logo} alt="Royal Trading Logo"></Image>
                            </div>
                        </Col>
                    </Row>
                    <Row className='w-100 m-auto align-items-center'>
                        <Col xs={12} sm={6} className="rt_banner_order_1">
                            <div className='rt_about_logo_bg'>
                                <Image src={about_image} alt="Royal Trading Image"></Image>
                            </div>
                        </Col>
                        <Col xs={12} sm={6} className="rt_banner_order_2">
                            <div className="rt_about_content1">
                                <p>We take business serious and we believe that our customers are our partners who would lead us to success, because we simply believe successes does not happen without the loyalty of our customers, we strive to satisfy every customer by providing top quality merchandise, state of the art designs and competitive prices.</p>
                                <p>We hire certified designers in the USA, China and Turkey to develop our products and designs</p>
                                <p>We thank our customers for the support they’ve given us for the last 15 years, we thank them for taking us to the position we are at now and we promise we will keep our reputation for years and years to come, we also thank our professional team whom also were and still are taking us a step forward every single day.</p>
                            </div>
                        </Col>                        
                    </Row>
                </Container>        
            </div>
        </>
    );
}

export default About;
