import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col, Container, Image, Row } from 'react-bootstrap';
import Banner from '../Assets/Images/Home/banner.png'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';
import { useState } from 'react';

const BannerSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    const bannerData = useSelector(state => state.persistedReducer?.home?.banners);
    const [bannerload, setBannerload] = useState(true);
    return (
        <>
            <section className="rt_banner_section">
                <Container className='rt_custome_container'>
                    <Row>
                        <Col>
                        {!bannerData?.image ? <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', margin: "10px 10px" }} variant="text" animation="wave" height={550} />
 :
                                <Slider {...settings} className="rt_banner_slider_slick">

                                    <div className="rt_banner_slider_main_item">
                                    
                                        <Row className='align-items-center'>
                                            <Col lg={7} md={7} className="rt_banner_order_1">
                                                <div className="rt_banner_slider_main_text_content">
                                                    <h1>
                                                        {bannerData?.title}
                                                    </h1>
                                                    <p>{bannerData?.short_text}</p>
                                                    <button className="rt_banner_btn"> <Link to={`/${bannerData?.btn_link}`}>  {bannerData?.btn_text} </Link> </button>
                                                </div>
                                            </Col>
                                            <Col lg={5} md={5} className="rt_banner_order_2">
                                                <div className="rt_banner_slider_main_img">
                                              
                                                    <Image src={bannerData?.image}  alt={bannerData?.alt_tag}></Image>
                                                </div>

                                            </Col>

                                        </Row>
                                    </div>
                                     
                                    {/* <div className="rt_banner_slider_main_item">
                                    <Row className='align-items-center'>
                                        <Col lg={7} md={7} className="rt_banner_order_1">
                                            <div className="rt_banner_slider_main_text_content">
                                                <h1>
                                                    Fancy Wholesaler <br />
                                                    For Fancy Decor
                                                </h1>
                                                <p>One of the largest collection of Rugs available in all sizes along with Home Decor & Fancy Furnishing</p>
                                                <button className="rt_banner_btn"> <Link to="/productlist">  Shop Now </Link> </button>
                                            </div>
                                        </Col>
                                        <Col lg={5} md={5} className="rt_banner_order_2">
                                            <div className="rt_banner_slider_main_img">
                                                <Image src={Banner} alt="banner_img"></Image>
                                            </div>
                                        </Col>                                     
                                    </Row>
                                </div> */}

                                </Slider> 
                              }
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default BannerSlider;
