import { createSlice } from '@reduxjs/toolkit'

export const addressSlice = createSlice({
  name: 'address',
  initialState: {
      addressData:[]
  },
  reducers: {
    addressData: (state,action) => {
        state.addressData = action.payload
    },
  },
})

export const { addressData } = addressSlice.actions

export default addressSlice.reducer