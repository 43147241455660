import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../Assets/Css/LoginRegister.css";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { postData } from "../Helpers/request";
import { loader } from "../Reducer/homeReducer";

const Register = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [confirm_email, setConfirm_email] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [industry, setIndustry] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [confirm_pass, setConfirm_pass] = useState("");
  const [businessType, setBusinessType] = useState("");
  const [address, setAddress] = useState("");
  const [fax, setFax] = useState("");
  const [city, setCity] = useState("");
  const [postal, setPostal] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [tax, setTax] = useState("");

  const dispatch = useDispatch();
  const history = useNavigate();
  
  // register user
  const submitUser = async (e) => {
    e.preventDefault();
    if (password == confirm_pass && email == confirm_email) {
      dispatch(loader(true));
      const data = {
        first_name: firstname,
        last_name: lastname,
        email: email,
        password: password,
        phone: phone,
        industry,
        business_type: businessType,
        business_name: businessName,
        tax_ID: tax,
        address,
        fax,
        city,
        postal_code: postal,
        country,
        state,
      };
      const response = await postData("/user/register", data);
      if (response.data.status == 1) {
        dispatch(loader(false));
        swal(response.data.message, "", "success");
        history("/login");
      } else {
        dispatch(loader(false));
        return swal(response.data.message, "", "error");
      }
    } else {
      setPassword("");
      setConfirm_pass("");
      setEmail("");
      setConfirm_email("");
      if(email != confirm_email){
        return swal("Email & Confirm Email do not match!", "", "error");
      }else{
      return swal("Password & Confirm Password do not match!", "", "error");
    }
  }
  };
  const [buttonLoading, setButtonLoading] = useState(false);
  return (
    <>
      <section className="rt_login_section">
        <Container className="rt_custome_header_container">
          <Row className="rt_login_main_bg">
            <Col lg={6} className="p-0">
              <div className="rt_login_form_main rt_register">
                <div className="rt_login_form">
                  <Row>
                    <Col lg={11} md={9} className="m-auto">
                      <div className="mb-3">
                        <h2>CREATE AN ACCOUNT</h2>
                      </div>
                      <Form onSubmit={submitUser}>
                        <h5>Personal Information:</h5>
                        <Row className="mb-4">
                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>First Name *</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter First Name"
                                value={firstname}
                                onChange={(e) => setFirstname(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Last Name * </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Last Name"
                                value={lastname}
                                onChange={(e) => setLastname(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                       
                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Email address * </Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Enter Your Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Confirm Email * </Form.Label>
                              <Form.Control
                                type="email"
                                placeholder="Enter Your Confirm Email"
                                value={confirm_email}
                                onChange={(e) => setConfirm_email(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <h5>Company Information:</h5>
                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Industry </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Industry Name"
                                value={industry}
                                onChange={(e) => setIndustry(e.target.value)}
                              />
                            </Form.Group>
                            </Col>
                            <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Business Name * </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Business Name"
                                value={businessName}
                                onChange={(e) =>
                                  setBusinessName(e.target.value)
                                }
                                required
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Business Type </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Business Type"
                                value={businessType}
                                onChange={(e) =>
                                  setBusinessType(e.target.value)
                                }
                              />
                            </Form.Group>
                            </Col>
                            <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Tax ID/EIN * </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Your Tax ID/EIN"
                                value={tax}
                                onChange={(e) => setTax(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={12} md={12}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Address * </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Enter Your Address"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Phone Number * </Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter Your Phone Number"
                                value={phone}
                                onChange={(e) => setPhone(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Fax</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Fax"
                                value={fax}
                                onChange={(e) => setFax(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>City * </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="City"
                                value={city}
                                onChange={(e) => setCity(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Postal Code *</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Postal Code"
                                value={postal}
                                onChange={(e) => setPostal(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Country * </Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>

                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>State *</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="State"
                                value={state}
                                onChange={(e) => setState(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Password *</Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                            </Form.Group>
                          </Col>
                          <Col lg={6} md={6}>
                            <Form.Group
                              className="mb-3"
                              controlId="formBasicPassword"
                            >
                              <Form.Label>Confirm Password *</Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="Confirm Password"
                                value={confirm_pass}
                                onChange={(e) =>
                                  setConfirm_pass(e.target.value)
                                }
                                required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Button
                          type="submit"
                          className="rt_login_btn"
                          disabled={buttonLoading}
                        >
                          {buttonLoading ? (
                            <CircularProgress className="mj_loader" />
                          ) : (
                            "Sign up"
                          )}
                        </Button>
                      </Form>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={6} className="p-0">
              <div className="rt_login_creating_b_main rt_register_creating_b_main">
                <div className="rt_login_title_text rt_register_title_text">
                  <h2>Login</h2>
                  <p> Already have a Royal Trading account? </p>
                  <Button
                    type="submit"
                    className="rt_login_creat_an_account_btn"
                  >
                    <Link to="/login"> Login </Link>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Register;
