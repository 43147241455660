import React from 'react';
import Slider from "react-slick";
import { Col, Container, Image, Row } from 'react-bootstrap';
import chairImage1 from '../Assets/Images/Home/stool/chair1.png';
import chairImage2 from '../Assets/Images/Home/stool/chair2.png';
import chairImage3 from '../Assets/Images/Home/stool/chair3.png';
import chairImageMain from '../Assets/Images/Home/stool/chair-main.png';
import { NavLink } from "react-router-dom";

const BarStools = () => {
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };
    return (
        <>
            <div className='rt_area_section'>
                <Container className='rt_custome_container'>
                    <Row className='m-auto w-100'>
                        <Col xs={12} md={4} lg={4}>
                            <div className='rt_area_content_right'>
                                <NavLink to='/product-category/bar-stool'>
                                    <Image src={chairImageMain} alt="Area Rugs"></Image>
                                </NavLink>
                            </div>
                        </Col> 
                        <Col xs={12} md={12} lg={8}>
                            <div className='rt_heading2'>
                                <h2>Bar Stools</h2>
                            </div>
                            <Row className='m-auto w-100'>
                                <div className='rt_area_content_main p-0'>
                                    <Slider {...settings}>
                                        <div className='rt_area_content'>
                                            <NavLink to='/product-category/bar-stool'>
                                                <Image src={chairImage1} alt="Area Rugs"></Image>
                                                <h3>Decoration awesome chair</h3>
                                                {/* <h5>$65.00</h5> */}
                                            </NavLink>
                                        </div>
                                        <div className='rt_area_content'>
                                            <NavLink to='/product-category/bar-stool'>
                                                <Image src={chairImage2} alt="Area Rugs"></Image>
                                                <h3>Decoration awesome chair</h3>
                                                {/* <h5>$65.00</h5> */}
                                            </NavLink>
                                        </div>
                                        <div className='rt_area_content'>
                                            <NavLink to='/product-category/bar-stool'>
                                                <Image src={chairImage3} alt="Area Rugs"></Image>
                                                <h3>Decoration awesome chair</h3>
                                                {/* <h5>$65.00</h5> */}
                                            </NavLink>
                                        </div>
                                        <div className='rt_area_content'>
                                            <NavLink to='/product-category/bar-stool'>
                                                <Image src={chairImage1} alt="Area Rugs"></Image>
                                                <h3>Decoration awesome chair</h3>
                                                {/* <h5>$65.00</h5> */}
                                            </NavLink>
                                        </div>
                                        <div className='rt_area_content'>
                                            <NavLink to='/product-category/bar-stool'>
                                                <Image src={chairImage2} alt="Area Rugs"></Image>
                                                <h3>Decoration awesome chair</h3>
                                                {/* <h5>$65.00</h5> */}
                                            </NavLink>
                                        </div>
                                        <div className='rt_area_content'>
                                            <NavLink to='/product-category/bar-stool'>
                                                <Image src={chairImage3} alt="Area Rugs"></Image>
                                                <h3>Decoration awesome chair</h3>
                                                {/* <h5>$65.00</h5> */}
                                            </NavLink>
                                        </div>
                                        <div className='rt_area_content'>
                                            <NavLink to='/product-category/bar-stool'>
                                                <Image src={chairImage1} alt="Area Rugs"></Image>
                                                <h3>Decoration awesome chair</h3>
                                                {/* <h5>$65.00</h5> */}
                                            </NavLink>
                                        </div>
                                        <div className='rt_area_content'>
                                            <NavLink to='/product-category/bar-stool'>
                                                <Image src={chairImage2} alt="Area Rugs"></Image>
                                                <h3>Decoration awesome chair</h3>
                                                {/* <h5>$65.00</h5> */}
                                            </NavLink>
                                        </div>
                                        <div className='rt_area_content'>
                                            <NavLink to='/product-category/bar-stool'>
                                                <Image src={chairImage3} alt="Area Rugs"></Image>
                                                <h3>Decoration awesome chair</h3>
                                                {/* <h5>$65.00</h5> */}
                                            </NavLink>
                                        </div>
                                    </Slider>
                                </div>
                            </Row>
                        </Col>                           
                    </Row>    
                </Container>
            </div>
        </>
    );
}

export default BarStools;
