import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
      userData:[{isLogin:false}],
      orderData:[]
  },
  reducers: {
    userData: (state,action) => {
        state.userData = action.payload
    },
    orderData:(state,action) => {
      state.orderData = action.payload
  }
  },
})

export const { userData, orderData } = userSlice.actions

export default userSlice.reducer