import React, { useState, useEffect } from "react";
import {
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  Row,
  Offcanvas,
  InputGroup,
  FormControl,
  Accordion,
  Form,
} from "react-bootstrap";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../Assets/Css/Header.css";
import logo from "../Assets/Images/logo.svg";
import { FiSearch } from "react-icons/fi";
import { HiOutlineUser } from "react-icons/hi";
import { CgMenuRight } from "react-icons/cg";
import { BsCart2 } from "react-icons/bs";
import { CgClose } from "react-icons/cg";
import { AiOutlineHeart } from "react-icons/ai";
import HeaderImg from "../Assets/Images/header/header_img.jpg";
import HeaderImg2 from "../Assets/Images/header/header_new.jpg";
import { useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { wishListcount } from "../Reducer/productReducers";
import { Skeleton } from "@mui/material";

const Header = ({ menuData }) => {
  let auth = useSelector((state) => state.persistedReducer.user.userData);
  const navigate = useNavigate();
  useEffect(() => {
    document.body.className = window.location.pathname;
    return () => { document.body.className = ''; }
   
  }, [window.location.pathname]);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isShown, setIsShown] = useState(false);
  const [search_text, setSearch_text] = useState("");
  const [menu, setMenu] = useState(false);
  const handleCloseMenu = () => setMenu(false);
  const handleMenu = () => setMenu(true);
  const user = useSelector((state) => state.persistedReducer.user?.userData);
  const whishlistcount = useSelector(
    (state) => state.persistedReducer.wish?.whishlistlength
  );
  const cartlistcount = useSelector(
    (state) => state.persistedReducer.cart?.cartcount
  );

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });



  const isSticky = (e) => {
    const header = document.querySelector(".rt_header_main");
    const scrollTop = window.scrollY;
    scrollTop >= 150
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };

  return (
    <>


      <div className="rt_header_main" >
        {/* <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', margin: "10px 11%" }} variant="text" animation="wave" height={90} /> */}
        <Container className="rt_custome_header_container">
          <Row className="m-auto w-100">
            <Col className="p-0">
              <div className="rt_header_main_d">
                <div className="rt_header_logo">
                  <NavLink to="/">
                    <Image src={logo} alt="Royal Trading"></Image>
                  </NavLink>
                </div>
                <div className="rt_header_menu">
                  <ul className="rt_header_menu_first_ul">

                    <ul className="rt_header_menu_skeleton">
                      {!menuData.length && [...Array(5)].map(val =>
                        <li>  <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', margin: "0px 5px" }} variant="text" animation="wave" height={20} /> </li>
                      )}
                    </ul>


                    {menuData &&
                      menuData.map((elem, ind) => (

                        <li
                          key={"parent" + ind.toString()}
                          className={
                            elem.submenu.length == 0
                              ? "rt_header_menu_parent_li"
                              : "rt_header_menu_parent_li rt_mega_submenu"
                          }
                          onMouseEnter={() => elem.submenu.length ? setIsShown(true) : ""}
                          onMouseLeave={() => setIsShown(false)}
                        >

                          <NavLink
                            to={"/" + elem?.slug}
                            className="rt_mega-menu_parent_a"
                          >
                            {elem?.name}
                          </NavLink>

                          {elem.submenu && elem.submenu.length > 0 && (
                            <div className="rt_mega_menu_item_main">
                              <div className="rt_mega_menu_item_list">
                                <h3> Shop by categories </h3>
                                <ul>
                                  {elem.submenu &&
                                    elem.submenu.map((subitem, index) => {
                                      return (
                                        <li
                                          className="rt_header_menu_child_li"
                                          key={"sub-" + index.toString()}
                                        >
                                          <NavLink to={"/" + subitem?.slug}>
                                            {subitem.name}
                                          </NavLink>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>

                              <div className="rt_mega_menu_item_img">
                                <Image
                                  src={elem.img_url}
                                  alt="head_img"
                                ></Image>
                              </div>
                            </div>
                          )}
                        </li>
                      ))}

                    <span className="overlay_menu"></span>
                  </ul>
                </div>
                <div className="rt_header_icon">
                  <ul>
                    <li className="d-mobile-none" onClick={handleShow}>
                      <FiSearch />
                    </li>
                    <li
                      className="d-mobile-none"
                      onClick={() => {
                        user.isLogin
                          ? navigate("/myaccount")
                          : navigate("/login");
                      }}
                    >
                      {user.isLogin ? (
                        <Stack direction="row" spacing={2}>
                          <Avatar
                            sx={{ width: 35, height: 35 }}
                            className="rt_login_user_name"
                          >
                            {user.first_name[0].toUpperCase()}
                          </Avatar>
                        </Stack>
                      ) : (
                        <HiOutlineUser />
                      )}
                    </li>
                    <li
                      className="d-mobile-none"
                      onClick={() => navigate("/wishlist")}
                    >
                      <AiOutlineHeart />
                      <span>{whishlistcount == 0 ? "" : whishlistcount}</span>
                    </li>
                    <li
                      className="d-mobile-none"
                      onClick={() => navigate("/cart")}
                    >
                      <BsCart2 />
                      <span>{cartlistcount == 0 ? "" : cartlistcount}</span>
                    </li>
                    <li
                      className="rt_header_mobile_menu_icon"
                      onClick={handleMenu}
                    >
                      <CgMenuRight />
                    </li>
                  </ul>
                </div>

                {/* header search bar  start */}
                <Offcanvas
                  show={show}
                  onHide={handleClose}
                  placement="top"
                  className="rt_search_canvas"
                >
                  <Container>
                    <Offcanvas.Header closeButton className="">
                      <Offcanvas.Title>
                        <h3> Search </h3>
                      </Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                      <Container>
                        <Row>
                          <Col lg={12} className="p-0">
                            <div className="rt_header_search_main">
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                  navigate("/search/" + search_text);
                                  window.scrollTo(0, 0);
                                  setSearch_text("");
                                  setShow(false);
                                }}
                              >
                                <InputGroup size="lg">
                                  <FormControl
                                    aria-label="Large"
                                    aria-describedby="inputGroup-sizing-sm"
                                    placeholder="search"
                                    onFocus={(e) => (e.target.placeholder = "")}
                                    onBlur={(e) =>
                                      (e.target.placeholder = "search")
                                    }
                                    value={search_text}
                                    onChange={(e) =>
                                      setSearch_text(e.target.value)
                                    }
                                  />
                                  <InputGroup.Text id="search">
                                    <CgClose
                                      onClick={() => {
                                        setShow(!show);
                                      }}
                                    />
                                  </InputGroup.Text>
                                </InputGroup>
                              </Form>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Offcanvas.Body>
                  </Container>
                </Offcanvas>
                {/* header search bar  end */}
              </div>
            </Col>
          </Row>
        </Container>

        {/* hover menu overlay span */}
        {isShown && <span className="rt_menu_ovrlay"> </span>}
        {/* hover menu overlay span */}

        {/* mobile menu start */}
        <Offcanvas
          show={menu}
          onHide={handleCloseMenu}
          className="rt_mobile_menu_main"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <div className="rt_header_logo">
                <ul>
                  <li>
                    <span onClick={handleShow}>
                      <FiSearch />
                    </span>
                  </li>
                  <li
                    onClick={() => {
                      setMenu(false);
                      user.isLogin
                        ? navigate("/myaccount")
                        : navigate("/login");
                    }}
                  >
                    <HiOutlineUser />
                  </li>
                  <li
                    onClick={() => {
                      setMenu(false);
                      navigate("/wishlist");
                    }}
                  >
                    <AiOutlineHeart />
                  </li>
                  <li
                    onClick={() => {
                      setMenu(false);
                      navigate("/cart");
                    }}
                  >
                    <BsCart2 />
                  </li>
                </ul>

                <NavLink to="/">
                  <Image src={logo} alt="Royal Trading"></Image>
                </NavLink>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="rt_mobile_menu_content_main">
              
              {menuData &&
                      menuData.map((elem, ind) =>
                      <Accordion className={elem?.submenu.length ? "" : "arrownone"}>
                <Accordion.Item eventKey={ind}>
                  <Accordion.Header ><span onClick={()=> navigate("/" + elem?.slug) }>{elem?.name}</span></Accordion.Header>
                  {elem?.submenu.length ?
                  <Accordion.Body>
                    <div className="rt_mobile_menu_item_main">
                      <h5> Shop by categories </h5>
                      <ul>
                      {elem.submenu &&
                                    elem.submenu.map((subitem, index) => 
                        <li className="rt_header_menu_child_li">
                          <NavLink to={"/" + subitem.slug}>{subitem.name}</NavLink>
                        </li>
                          )}
                      </ul>
                    </div>
                  </Accordion.Body> : ""}
                </Accordion.Item>
              </Accordion>
                      )}
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        {/* mobile menu end */}
      </div>


    </>
  );
};

export default Header;
