import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../Assets/Css/Footer.css";
import logo from "../Assets/Images/logo.svg";
import { BsEnvelope } from "react-icons/bs";
import { FiPhoneCall } from "react-icons/fi";
import { FiMapPin } from "react-icons/fi";
import footerImage1 from "../Assets/Images/Footer/badge1.png";
import footerImage2 from "../Assets/Images/Footer/badge2.png";
import footerImage3 from "../Assets/Images/Footer/badge3.png";
import footerImage4 from "../Assets/Images/Footer/badge4.png";
import paypal from "../Assets/Images/Footer/paypal.png";
import visa from "../Assets/Images/Footer/visa.png";
import master from "../Assets/Images/Footer/master.png";
import stripe from "../Assets/Images/Footer/stripe.png";
import { FaFacebookF } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Newsletter from "../Front/Newsletter";
import { useSelector } from "react-redux";

const Footer = () => {
  const [expanded, setExpanded] = React.useState(false);
  const session = useSelector(
    (state) => state.persistedReducer.home.session_id
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Newsletter />
      <div className="rt_footer d-lg-block d-none">
        <Container className="rt_custome_container">
          <Row className="m-auto w-100">
            <Col md={3} lg={10} xl={3} className="m-auto">
              <div className="rt_footer_content rt_footer_content_left">
                <div className="rt_f_logo">
                  <NavLink to="/">
                    <Image src={logo} alt="Royal Trading"></Image>
                  </NavLink>
                </div>

                <p>
                  Royal Trading Inc was founded in 2001 specializing in bathroom
                  products, area rugs and drapery hardware, our focus is to be
                  your retail store one stop shop for bathroom, drapery hardware
                  and area rugs <NavLink to="/about-us">READ MORE...</NavLink>{" "}
                </p>
              </div>
            </Col>
            <Col md={9} lg={12} xl={9} className="p-0">
              <Row className="justify-content-center">
                <Col md={3} lg={3} xl={3}>
                  <div className="rt_footer_content">
                    <h2>Quick Links</h2>
                    <ul>
                      <li>
                        <NavLink to="/product-category/collection">
                          Collection
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/product-category/new-arrivals">
                          New Arrivals
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services">Services</NavLink>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={3} lg={3} xl={3}>
                  <div className="rt_footer_content">
                    <h2>Help</h2>
                    <ul>
                      <li>
                        <NavLink to="/about-us">About Us</NavLink>
                      </li>
                      {/* <li><NavLink to='#'>Shipping & Returns</NavLink></li> */}
                      <li>
                        <NavLink to="/faq">FAQ</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact-us">Contact Us</NavLink>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={3} lg={3} xl={3}>
                  <div className="rt_footer_content rt_footer_content1">
                    <h2>Contact Us</h2>
                    <ul>
                      <li
                        onClick={(e) => {
                          window.location.href = `mailto:royalchicago@gmail.com`;
                        }}
                      >
                        <NavLink to="#">
                          <BsEnvelope /> royalchicago@gmail.com
                        </NavLink>
                      </li>
                      <li
                        onClick={(e) => {
                          window.location.href = `tel:+17736969552`;
                        }}
                      >
                        <NavLink to="#">
                          <FiPhoneCall /> +1 (773) 696-9552
                        </NavLink>
                      </li>
                      <li
                        onClick={() => {
                          window.open(
                            "https://www.google.com/maps/place/3301+W+47th+Pl,+Chicago,+IL+60632,+USA/@41.808899,-87.7156057,15z/data=!4m13!1m7!3m6!1s0x880e31e54e5a14d7:0x772638e73499fb1c!2s3301+W+47th+Pl,+Chicago,+IL+60632,+USA!3b1!8m2!3d41.8066948!4d-87.7066498!3m4!1s0x880e31e54e5a14d7:0x772638e73499fb1c!8m2!3d41.8066948!4d-87.7066498",
                            "_blank"
                          );
                        }}
                      >
                        <NavLink to="#">
                          <FiMapPin /> 3301 W 47th Pl
                          <br />
                          Chicago, IL 60632
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={3} lg={6} xl={3}>
                  <div className="rt_footer_content rt_footer_content_badge">
                    <ul>
                      <li>
                        <NavLink to="#">
                          <Image
                            src={footerImage1}
                            alt="badge royal trading"
                          ></Image>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <Image
                            src={footerImage2}
                            alt="badge royal trading"
                          ></Image>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <Image
                            src={footerImage3}
                            alt="badge royal trading"
                          ></Image>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="#">
                          <Image
                            src={footerImage4}
                            alt="badge royal trading"
                          ></Image>
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Mobile Footer */}
      <div className="rt_footer_mobile d-sm-block d-lg-none">
        <Container className="rt_custome_container">
          <Row className="w-100 m-auto">
            <NavLink to="/">
              <Image src={logo} alt="Royal Trading"></Image>
            </NavLink>
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography sx={{ color: "text.secondary" }}>
                  About Us
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <p className="tr_mobile_para">
                    Royal Trading Inc was founded in 2001 specializing in
                    bathroom products, area rugs and drapery hardware, our focus
                    is to be your retail store one stop shop for bathroom,
                    drapery hardware and area rugs{" "}
                    <NavLink to="/about-us">READ MORE...</NavLink>{" "}
                  </p>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
              >
                <Typography sx={{ color: "text.secondary" }}>
                  Quick Links
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="rt_footer_content">
                    <ul>
                      <li>
                        <NavLink to="/product-category/collection">
                          Collection
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/product-category/new-arrivals">
                          New Arrivals
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/services">Services</NavLink>
                      </li>
                    </ul>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
              >
                <Typography sx={{ color: "text.secondary" }}>Help</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="rt_footer_content">
                    <ul>
                      <li>
                        <NavLink to="/about-us">About Us</NavLink>
                      </li>
                      {/* <li><NavLink to='#'>Shipping & Returns</NavLink></li> */}
                      <li>
                        <NavLink to="/faq">FAQ</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact-us">Contact Us</NavLink>
                      </li>
                    </ul>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <Typography sx={{ color: "text.secondary" }}>
                  Contact Us
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className="rt_footer_content rt_footer_content1">
                    <ul>
                      <li
                        onClick={(e) => {
                          window.location.href = `mailto:royalchicago@gmail.com`;
                        }}
                      >
                        <NavLink to="#">
                          <BsEnvelope /> royalchicago@gmail.com
                        </NavLink>
                      </li>
                      <li
                        onClick={(e) => {
                          window.location.href = `tel:+17736969552`;
                        }}
                      >
                        <NavLink to="#">
                          <FiPhoneCall /> +1 (773) 696-9552
                        </NavLink>
                      </li>
                      <li
                        onClick={() => {
                          window.open(
                            "https://www.google.com/maps/place/3301+W+47th+Pl,+Chicago,+IL+60632,+USA/@41.808899,-87.7156057,15z/data=!4m13!1m7!3m6!1s0x880e31e54e5a14d7:0x772638e73499fb1c!2s3301+W+47th+Pl,+Chicago,+IL+60632,+USA!3b1!8m2!3d41.8066948!4d-87.7066498!3m4!1s0x880e31e54e5a14d7:0x772638e73499fb1c!8m2!3d41.8066948!4d-87.7066498",
                            "_blank"
                          );
                        }}
                      >
                        <NavLink to="#">
                          <FiMapPin /> 3301 W 47th Pl
                          <br />
                          Chicago, IL 60632
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Row>
        </Container>
      </div>
      {/* Mobile Footer */}
      <div className="rt_footer_bottom">
        <Container className="rt_custome_container">
          <Row className="w-100 m-auto align-items-center">
            <Col xs={12} md={12} lg={6}>
              <div className="rt_footer_bottom_content1">
                <ul>
                  <li>
                    <Image src={paypal} alt="paypal"></Image>
                  </li>
                  <li>
                    <Image src={visa} alt="visa"></Image>
                  </li>
                  <li>
                    <Image src={master} alt="master"></Image>
                  </li>
                  <li>
                    <Image src={stripe} alt="stripe"></Image>
                  </li>
                </ul>
              </div>
            </Col>
            <Col xs={12} md={12} lg={6}>
              <div className="rt_footer_bottom_content">
                {/* <p>Royal Chicago© 2022 All rights reserved. Designed and Developed by: <NavLink to="#" onClick={() => { window.open("http://belgiumwebnet.com/") }}>Belgium WebNet</NavLink></p> */}
                <p className=" text-end">© 2023 Royal Chicago. All rights reserved. </p>
              </div>
            </Col>
            <Col xs={12} md={12} lg={4} className="d-none">
              <div className="rt_footer_bottom_content">
                <ul>
                  <li>
                    <NavLink to="#">
                      <FaFacebookF />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="#">
                      <FaTwitter />
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="#">
                      <FaInstagram />
                    </NavLink>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Footer;
