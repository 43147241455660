import {configureStore} from "@reduxjs/toolkit";
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'
import cartReducer from '../Reducer/cartReducer'
import userReducers from "../Reducer/userReducers";
import addressReducer from "../Reducer/addressReducer";
import homeReducer from "../Reducer/homeReducer";
import wishlistReducer from "../Reducer/wishlistReducer";
import productReducers from "../Reducer/productReducers";
import localStorage from "redux-persist/es/storage";
import sessionStorage from "redux-persist/es/storage/session";

const persistConfig = {
    key:'root',
    storage:localStorage
}
const persistConfigSession = {
    key:'session',
    storage:sessionStorage
}

const persistedReducer = persistReducer(persistConfig, combineReducers({cart:cartReducer, user:userReducers, address:addressReducer, home:homeReducer,wish:wishlistReducer}))
const persistedReducerSession = persistReducer(persistConfigSession, combineReducers({product:productReducers}))

const store = configureStore({
    reducer: {
        persistedReducer:persistedReducer,
        persistedReducerSession:persistedReducerSession
    },
})

const persistor = persistStore(store)
export {store,persistor}    