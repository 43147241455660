import { CircularProgress, InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { Button, Col, Container, Form, Image, Row } from "react-bootstrap";
import { Link, Navigate, NavLink, useNavigate } from "react-router-dom";
import "../Assets/Css/LoginRegister.css";
import Time from "../Assets/Images/time-traced.png";
import Lcart from "../Assets/Images/login_cart.png";
import Gbox from "../Assets/Images/gift_box.png";
import axios from "axios";
import swal from "sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { cartCountvalue } from "../Reducer/cartReducer";
import { userData } from "../Reducer/userReducers";
import { base_url, postData, postHeader } from "../Helpers/request";
import { whishlistlength } from "../Reducer/wishlistReducer";
import { sessionId } from "../Reducer/homeReducer";
import { categoryList } from "../Reducer/productReducers";

const Login = () => {
  const history = useNavigate();
  const [login, setLogin] = useState(false);
  const [errmsg, setErrmsg] = useState(false);
  const [errmsg2, setErrmsg2] = useState(false);
  const [msg, setMsg] = useState("");
  const dispatch = useDispatch();
  const sessionid = useSelector(
    (state) => state.persistedReducer.home?.sessionId
  );

  /*search code*/
  const [forgotpassword, setForgotpassword] = useState(false);
  //login
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  //forgotpass
  const [f_email, setF_email] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const signin = () => {
    setLogin(true);
    setForgotpassword(false);
  };

  // login user

  const handlesubmitlogin = async (e) => {
    setErrmsg(false);
    e.preventDefault();
    setButtonLoading(true);
    const response = await postData("/user/login", {
      email,
      password,
      session_id: sessionid ? sessionid : "",
    });
    if (response.data.status == 1) {
      setButtonLoading(false);
      history(-1);
      var user = {
        token: response.data.data.token,
        user_id: response.data.data.user_id,
        email: response.data.data.email,
        first_name: response.data.data.first_name,
        last_name: response.data.data.last_name,
        phone: response.data.data.phone,
        isLogin: true,
      };
      dispatch(cartCountvalue(response.data.data.cart_count));
      dispatch(whishlistlength(response.data.data.wishlist_count));
      dispatch(userData(user));
      dispatch(sessionId(""));
      dispatch(categoryList(""));
      setEmail("");
      setPassword("");
      setLogin(false);
    } else {
      setButtonLoading(false);
      setErrmsg(true);
      setMsg(response.data.message);
      swal(response.data.message, "", "error");
    }
  };

  // forgot password
  const handleforgotpassword = async (e) => {
    setButtonLoading(true);
    e.preventDefault();
    var data = {
      email: f_email,
    };
    const response = await postData("/user/forget_password", data);
    if (response.data.status == 1) {
      setButtonLoading(false);
      setF_email("");
      signin();
      return swal(response.data.message, "", "success");
    } else {
      setButtonLoading(false);
      setErrmsg2(true);
      setMsg(response.data.message);
    }
  };
  const [buttonLoading, setButtonLoading] = useState(false);
  const isLogin = useSelector((state) => state.persistedReducer.user.isLogin);
  return (
    <>
      <section className="rt_login_section">
        <Container className="rt_custome_header_container">
          <Row className="rt_login_main_bg">
            <Col lg={5} className="p-0">
              <div className="rt_login_form_main">
                <div className="rt_login_form">
                  <Row>
                    <Col lg={10} md={11} xs={11} className="m-auto ">
                      <div>
                        <h2>LOGIN</h2>
                        <p>Continue to Royal Trading</p>
                      </div>
                     { !forgotpassword? <Form onSubmit={handlesubmitlogin}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="example@email.com"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </Form.Group>
                        <div className="rt_forget_text_box">
                            <NavLink to="#" onClick={()=>setForgotpassword(true)}>Forget Password</NavLink>
                        </div>
                        <Button
                          type="submit"
                          className="rt_login_btn"
                          disabled={buttonLoading}
                        >
                          {buttonLoading ? (
                            <CircularProgress className="mj_loader" />
                          ) : (
                            "Login"
                          )}
                        </Button>
                      </Form>: <Form onSubmit={handleforgotpassword}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label>Email</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="example@email.com"
                            value={f_email}
                            onChange={(e) => setF_email(e.target.value)}
                            required
                          />
                        </Form.Group>
                        <Button
                          type="submit"
                          className="rt_login_btn"
                          disabled={buttonLoading}
                        >
                               {buttonLoading ? <CircularProgress className="rcs_loader" /> : "Continue"}
                        </Button>
                        {errmsg2 ?
                                <Row>
                                    <Col className="min-height_col_error_msg">
                                        <div className="rcs_signup_link rcs_signup_link_error ">
                                            <p style={{ color: "red" }}>{msg}</p>
                                        </div>
                                    </Col>
                                </Row> : ""}
                            <Row>
                                <Col>
                                    <div className="rcs_signup_link ">
                                        <p><span>Remember your password</span> ? <NavLink to="#" onClick={() => setForgotpassword(false)}>Login</NavLink></p>
                                    </div>
                                </Col>
                            </Row>
                      </Form>
                      }
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={7} className="p-0 ">
              <div className="rt_login_creating_b_main">
                <div className="rt_login_title_text">
                  <h2>
                    BENEFITS OF CREATING <br />
                    AN ACCOUNT
                  </h2>
                  <p>Enjoy additional benefits when you become our member</p>
                </div>
                <Row>
                  <Col lg={4} md={4} xs={10} className="m-auto">
                    <div className="rt_login_creating_box_main">
                      <img src={Time} />
                      <p>Easy access to order history, saved items and more</p>
                    </div>
                  </Col>
                  <Col lg={4} md={4} xs={10} className="m-auto">
                    <div className="rt_login_creating_box_main">
                      <img src={Lcart} />
                      <p>
                        {" "}
                        Faster checkout with stored shipping\and billing
                        information
                      </p>
                    </div>
                  </Col>
                  <Col lg={4} md={4} xs={10} className="m-auto">
                    <div className="rt_login_creating_box_main">
                      <img src={Gbox} />
                      <p> Exclusive offers, discounts and shipping upgrades</p>
                    </div>
                  </Col>
                </Row>
                <div>
                  <Button
                    type="submit"
                    className="rt_login_creat_an_account_btn"
                  >
                    <Link to="/register"> CREATE AN ACCOUNT </Link>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Login;
