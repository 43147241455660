import React, { useEffect, useState } from "react";
import { Col, Container, Form, Image, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../Assets/Css/ProductList.css";
import { BsArrowRight } from "react-icons/bs";
import { BsStarFill } from "react-icons/bs";
import productImage from "../Assets/Images/productList/product.png";
import { AiFillHeart } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { postData } from "../Helpers/request";
import { whishlistlength, wishlistData } from "../Reducer/wishlistReducer";
import { sessionId } from "../Reducer/homeReducer";
import { useNavigate } from "react-router";
import { userData } from "../Reducer/userReducers";
import Nodata from "../../src/Assets/Images/nodata.jpg";
import { Skeleton } from "@mui/material";
import { isTablet } from "react-device-detect";
import { ConstructionOutlined } from "@mui/icons-material";
import {MdOutlineDeleteOutline} from 'react-icons/md'

const WishList = () => {
  const user = useSelector((state) => state.persistedReducer.user?.userData);
  const sessionid = useSelector(
    (state) => state.persistedReducer.home?.sessionId
  );
  const wishlistitems = useSelector(
    (state) => state.persistedReducer.wish?.wishlistData
  );

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    wishlist();
  }, []);
  const wishlist = async () => {
    setLoading(true)
    const data = {
      user_id: user?.user_id ? user?.user_id : "",
      token: user?.token ? user?.token : "",
      session_id: sessionid ? sessionid : "",
    };
    const res = await postData("/order/wish_list", data);

    if (res.data?.status == 1) {
      setLoading(false)
      dispatch(wishlistData(res.data.data?.wishlist_data));
      console.log(res.data.message);
    } else {
      console.log(res.data.message);
    }
  };

  const removewishlist = async (product_id, type) => {
    const data = {
      user_id: user?.user_id ? user?.user_id : 0,
      session_id: sessionid ? sessionid : "",
      product_id: product_id,
      type: type,
      token: user?.token,
    };
    const res = await postData("/order/add_to_wishlist", data);
    if (res.data.status == 1) {
      var product = wishlistitems;
      var pdata = product?.filter(val => product_id != val.product_id)
      dispatch(wishlistData(pdata));
      dispatch(whishlistlength(res.data.data.total_count));
    } else {
      console.log(res.message);
    }
  };

  return (
    <>
      <div className="rt_productlist_section_bg">
        <Container className="rt_custome_container">
          <Row className="w-100 m-auto">
            <Col>
              <div className="rt_productlist_banner_content">
                <h1>WishList</h1>
                <ul>
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <BsArrowRight />
                  </li>
                  <li>
                    <NavLink to="#">WishList</NavLink>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="rt_product_list rt_product_wishlist">
        <Container className="rt_custome_container">
          <Row className="w-100 m-auto">
            {loading ?
              [...Array(8)].map(val =>
                <Col lg={3} md={4} xs={12} sm={6}>
                  {isTablet ?
                    <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', marginBottom: "15px" }} variant="text" animation="wave" height={280} /> :
                    <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', marginBottom: "15px" }} variant="text" animation="wave" height={225} />}</Col>)
              :
              wishlistitems?.length ? (
                wishlistitems?.map((res) => (
                  <Col lg={3} md={4} xs={12} sm={6}>
                    <div className="rt_product_list_content">
                      <div className="rt_product_list_content_top position-relative">
                        <NavLink to={`/product-detail/${res.slug}`}>
                          <Image
                            src={res.product_image}
                            alt="Royal Trading Product"
                          ></Image>
                        </NavLink>
                        {/* <ul className='rt_rating'>
                                        <li><h3><BsStarFill /> 4.5</h3></li>
                                        <li className='rt_line'></li>
                                        <li><h5>182</h5></li>
                                    </ul> */}
                      </div>
                      {/* <div className="tag-container">
                                    <div className="tag tag-drop">
                                        <h3 className="tag-title">25% OFF</h3>
                                        <div className="tag-tail"></div>
                                    </div>
                                </div> */}
                     <div className="d-flex">
                        <div className="rt_wishlist_icon rt_wishlist_icon_fill">
                        <span>
                          <AiFillHeart
                            onClick={() =>
                              removewishlist(res.product_id, res.product_type)
                            }
                          />
                        </span>
                      </div>
                      <div className="rt_wishlist_icon">
                        <span>
                          <MdOutlineDeleteOutline
                            onClick={() =>
                              removewishlist(res.product_id, res.product_type)
                            }
                          />
                        </span>
                      </div>
                                </div>
                     
                      <div className="rt_product_list_heading">
                        <NavLink to={`/product-detail/${res.slug}`}>
                          <h2>{res.product_name}</h2>
                        </NavLink>
                        {/* <p>$75 <del>$100</del></p> */}
                       {user.isLogin ?  <p>
                          {res.currency_icon}
                          {res.sale_price}
                        </p> :""}
                      </div>
                    </div>
                  </Col>
                ))
              ) : (
                <div className="rt_no_data">
                  <div>
                    <img src={Nodata} />
                    <h4>  No data Available </h4>
                  </div>
                </div>
              )}
            {/* <Col lg={3} md={4} xs={12} sm={6}>
                            <div className='rt_product_list_content'>
                                <div className='rt_product_list_content_top position-relative'>
                                    <NavLink to="/product-details">
                                        <Image src={productImage} alt="Royal Trading Product"></Image>
                                    </NavLink>                                    
                                </div>                                
                                <div className='rt_wishlist_icon rt_wishlist_icon_fill'>
                                    <span><AiFillHeart /></span>
                                </div>
                                <div className='rt_product_list_heading'>
                                    <NavLink to='#'>
                                        <h2>Ria Geometric Washable Rug</h2>
                                    </NavLink>
                                    <p>$75</p>
                                </div>
                            </div>  
                        </Col>
                        <Col lg={3} md={4} xs={12} sm={6}>
                            <div className='rt_product_list_content'>
                                <div className='rt_product_list_content_top position-relative'>
                                    <NavLink to="/product-details">
                                        <Image src={productImage} alt="Royal Trading Product"></Image>
                                    </NavLink>                                    
                                </div>                                
                                <div className='rt_wishlist_icon rt_wishlist_icon_fill'>
                                    <span><AiFillHeart /></span>
                                </div>
                                <div className='rt_product_list_heading'>
                                    <NavLink to='#'>
                                        <h2>Ria Geometric Washable Rug</h2>
                                    </NavLink>
                                    <p>$75</p>
                                </div>
                            </div>  
                        </Col>
                        <Col lg={3} md={4} xs={12} sm={6}>
                            <div className='rt_product_list_content'>
                                <div className='rt_product_list_content_top position-relative'>
                                    <NavLink to="/product-details">
                                        <Image src={productImage} alt="Royal Trading Product"></Image>
                                    </NavLink>                                    
                                </div>                                
                                <div className='rt_wishlist_icon rt_wishlist_icon_fill'>
                                    <span><AiFillHeart /></span>
                                </div>
                                <div className='rt_product_list_heading'>
                                    <NavLink to='#'>
                                        <h2>Ria Geometric Washable Rug</h2>
                                    </NavLink>
                                    <p>$75</p>
                                </div>
                            </div>  
                        </Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default WishList;
