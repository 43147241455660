import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  ListGroup,
  Row,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "../Assets/Css/ProductList.css";
import { BsArrowRight, BsChevronDown } from "react-icons/bs";
import { BsStarFill } from "react-icons/bs";
import productImage from "../Assets/Images/productList/product.png";
import { AiFillHeart } from "react-icons/ai";
import { AiOutlineHeart } from "react-icons/ai";
import { Pagination, Skeleton, Stack } from "@mui/material";
import {
  categoryList,
  demoData,
  filterData,
  filterSelect,
  productData,
  wishListcount,
  cartListcount,
  addFilterChipdata,
} from "../Reducer/productReducers";
import { loader, sessionId } from "../Reducer/homeReducer";
import { cartCountvalue } from "../Reducer/cartReducer";
import { whishlistlength, wishlistData } from "../Reducer/wishlistReducer";
import { useDispatch, useSelector } from "react-redux";
import { postData } from "../Helpers/request";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useHistory, useNavigate, useParams } from "react-router";
import Chip from "@mui/material/Chip";
import Nodata from "../../src/Assets/Images/nodata.jpg";
import { isMobileOnly, isTablet } from "react-device-detect";
import ProductListsComp from "./productListsComp";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ProductList = (props) => {
  const { slug, search } = useParams();
  const [cartadded, setCartAdded] = useState(false);
  const navigate = useNavigate();

  const [catediscription, setCatediscription] = useState("");
  const [productCount, setProductCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [showFilter, setShowFilter] = useState(true);
  const [sortby, setSortby] = useState("featured");
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const addedfilter = useSelector(
    (state) => state.persistedReducerSession.product?.filterSelect
  );
  const user = useSelector((state) => state.persistedReducer.user?.userData);
  const sessionid = useSelector(
    (state) => state.persistedReducer.home?.sessionId
  );
  const filterDetails = useSelector(
    (state) => state.persistedReducerSession.product?.filterData
  );
  const listcategory = useSelector(
    (state) => state.persistedReducerSession.product?.categoryList
  );
  const productlists = useSelector(
    (state) => state.persistedReducerSession.product?.productData
  );
  const filterChip = useSelector(
    (state) => state.persistedReducerSession.product?.addFilterChip
  );
  const dispatch = useDispatch();
  //const history = useNavigate()

  const handleDelete = (name) => {
    dispatch(addFilterChipdata(filterChip.filter((res) => res.name != name)));
  };

  useEffect(() => {
    productlist();
    getFilterData();
  }, [sortby, addedfilter, slug, page, search]);
  
  const productlist = async () => {
    setLoading(true)
    dispatch(loader(true));
    const data = {
      sort_by: sortby,
      category: slug,
      search_text: search,
      filterdata: addedfilter ? addedfilter : [],
      limit: 20,
      offset: (page - 1) * 20,
      user_id: user?.user_id ? user?.user_id : "",
      session_id: sessionid?.length ? sessionid : "",
    };
    const res = await postData("/product/productlist", data);
    if (res.data.status == 1) {
      dispatch(loader(false));
      setLoading(false)
      dispatch(productData(res.data?.data));
      setCatediscription(res.data.data?.description);
      setProductCount(res.data.data?.count);
      var count = (res.data.data?.count / 20).toFixed();
      if (count * 20 >= res.data.data?.count) {
        setPageCount(count);
      } else {
        setPageCount(Number(count) + 1);
      }
      if (res.data.data?.count == 0) {
        setShowFilter(false);
      } else {
        setShowFilter(true);
      }
    } else {
      dispatch(productData([]));
      setProductCount(0);
      setPageCount(0);
    }
  };

  const getFilterData = async () => {
    const data = {
      category: slug,
      search_text: search,
      session_id: sessionid ? sessionid : "",
      token: user?.token ? user?.token : "",
      user_id: user?.user_id ? user?.user_id : "",
    };
    if (data.category != listcategory) {
      const res = await postData("/product/productfilter", data);
      dispatch(filterData(res.data.data));
      var list = [];
      res.data.data?.normal_filters?.map((val) => {
        list.push({ title: val.title, filter: [], name: [] });
      });
      dispatch(filterSelect(list));
      // dispatch(demoData(list))
      dispatch(categoryList(data.category));
    }
  };

  const addtowishlist = async (val, wishindex) => {
    const data = {
      user_id: user.user_id ? user.user_id : "",
      session_id: sessionid ? sessionid : "",
      product_id: val.product_id,
      type: "",
      token: user ? user.token : "",
    };
    const res = await postData("/order/add_to_wishlist", data);

    if (res.data?.status == 1) {
      dispatch(whishlistlength(res.data.data.total_count));
      dispatch(wishlistData(res.data.data));
      var product = productlists?.result?.map(
        (obj, index) => (index == wishindex ? { ...obj, is_wishlist: res.data.data.wishlist_id } : obj)
      )
      dispatch(productData({ ...productlists, result: product }))
    } else {
      console.log(res.data?.message);
    }
  };



  const handlecheckChange = (title, value, name) => {
    var changedata = [...addedfilter];
    addedfilter?.map((val) => {
      var filterdata = [...val.filter];
      var fname = [...val.name];
      if (val.title == title) {
        if (val.filter?.includes(value)) {
          filterdata = val.filter?.filter((data) => data !== value);
          fname = val.name?.filter((data) => data !== name);
        } else {
          filterdata.push(value);
          fname.push(name);
        }

        changedata = changedata?.map((res) =>
          res.title == title
            ? Object.assign({}, { ...res, filter: filterdata, name: fname })
            : res
        );
        dispatch(filterSelect(changedata));
      }
    });
  };

  const checkboxcheck = (title, value, i) => {
    var data = false;
    if (addedfilter[i]?.title == title) {
      if (addedfilter[i]?.filter?.includes(`${value}`)) {
        data = true;
      }
    }
    return data;
  };
  const addFilterChip = (e, name, title, value, index) => {
    if (e.target.checked) {
      dispatch(
        addFilterChipdata([...filterChip, { name, title, value, index }])
      );
    } else {
      dispatch(addFilterChipdata(filterChip.filter((res) => res.name != name)));
    }
  };
  const handlePageChange = (e, value) => {
    setPage(value);
    window.scrollTo(0, 0);
  };

  const getCartStatus = (val) => {
    if (val) {
      toast.success(val?.msg, { autoClose: 5000, closeOnClick: true });
    }
  }
  return (
    <>
      {/* {loading && <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', margin: "10px 0" }} variant="text" animation="wave" height={250} />} */}

      <ToastContainer />
      <div className="rt_productlist_section_bg">



        <Container className="rt_custome_container">
          <Row className="w-100 m-auto">
            <Col>
              <div className="rt_productlist_banner_content">
                <h1>{productlists?.title ? productlists?.title : search}</h1>
                <ul>
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <BsArrowRight />
                  </li>
                  {productlists?.category_name ? (
                    <>
                      <li>
                        <NavLink to="#" onClick={() => navigate(-1)}>
                          {productlists?.category_name
                            ? productlists?.category_name
                            : ""}
                        </NavLink>
                      </li>
                      <li>
                        <BsArrowRight />
                      </li>
                    </>
                  ) : (
                    ""
                  )}
                  <li>
                    <NavLink to="#">{slug ? slug : search}</NavLink>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="rt_productlist">
        <Container className="rt_custome_container">
          <Row className="w-100 m-auto align-items-center">
            <Col lg={6} md={7} sm={12}>
              <div className="rt_productlist_filter price_brand" >

                <ul>


                  <>
                    {filterDetails?.normal_filters?.map((res, index) => (
                      <li>
                        <Dropdown
                          className="d-inline rt_dropfilter_main"
                          autoClose="outside"
                        >
                          <Dropdown.Toggle id="dropdown-autoclose-outside">
                            {res.title}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="rt_dropfilter_item_main">
                            <Dropdown.Item>
                              {res?.data.map((response) => (
                                <ListGroup>
                                  <ListGroup.Item>
                                    {" "}
                                    <Form.Check
                                      type="checkbox"
                                      label={response.name}
                                      //value={response.name}
                                      onClick={(e) => {
                                        handlecheckChange(
                                          res.title,
                                          response.value,
                                          response.name
                                        );
                                        addFilterChip(
                                          e,
                                          response.name,
                                          res.title,
                                          response.value,
                                          index
                                        );
                                      }}
                                      checked={checkboxcheck(
                                        res.title,
                                        response.value,
                                        index
                                      )}
                                    />
                                  </ListGroup.Item>
                                </ListGroup>
                              ))}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>

                      </li>
                    ))}
                  </>
                </ul>
              </div>
            </Col>

            <Col lg={6} md={5} sm={12}>

              {loading ? isMobileOnly ? <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', float: "right" }} variant="text" animation="wave" width={350} height={50} /> : <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', float: "right" }} variant="text" animation="wave" width={200} height={50} />
                :
                <div className="rt_productlist_shortby">
                  <ul>
                    <li>
                      <h5>SORT BY:</h5>
                    </li>
                    <li>
                      <Form.Select
                        aria-label="Default select example"
                        onChange={(e) => setSortby(e.target.value)}
                      >
                        {filterDetails?.sort_by?.map((res) => (
                          <option value={res.value} className="">{res.name}</option>
                        ))}
                       </Form.Select>
                    </li>
                  </ul>
                </div>
              }
            </Col>

            <Col lg={12} xs={12}>
              <div className="rt_filter_tage_main">
                <Stack direction="row" spacing={1}>
                  {filterChip?.length
                    ? filterChip?.map((res) => (
                      <Chip
                        label={res.name}
                        variant="outlined"
                        onDelete={() => {
                          handleDelete(res.name);
                          handlecheckChange(res.title, res.value, res.name);
                          checkboxcheck(res.title.res.value, res.index);
                        }}
                      />
                    ))
                    : ""}
                </Stack>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="rt_product_list">
        <Container className="rt_custome_container">
          <Row className="w-100 m-auto">
            <Col lg={12} md={12} sm={12}>
              <div className="rt_productlist_result">
                {loading ? isMobileOnly ? <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', margin: "10px auto", }} variant="text" animation="wave" width={220} height={25} /> : <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', marginBottom: "10px", }} variant="text" animation="wave" width={220} height={25} /> :
                  <p>
                    Showing {productlists.count} of {productlists.count} results
                  </p>}
              </div>
            </Col>
            {loading ? [...Array(16)].map(val => <Col lg={3} md={4} sm={6} xs={12} >{isTablet ? <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', marginBottom: "15px" }} variant="text" animation="wave" height={280} /> : <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', marginBottom: "15px" }} variant="text" animation="wave" height={350} />}</Col>) :
              productlists?.result?.length ? (
                productlists?.result?.map((res, index) => (
                  <ProductListsComp data={res} index={index} cartStatus={getCartStatus} />
                ))
              ) : (
                <div className="rt_no_data">
                  <div>
                    <img src={Nodata} alt="" />
                    <h4>  No data Available </h4>
                  </div>
                </div>
              )}
          </Row>
          <Row className="m-auto w-100">
            <div className="text-center rt_pagination">
              <Stack spacing={2}>
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default ProductList;


