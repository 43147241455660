import { createSlice } from '@reduxjs/toolkit'

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
      sessionId:'',
      menudata:[],
      bannerData:[],
      banners:[],
      currencyData:[],
      currencyIcon:'$',
      currencyCode:'USD',
      currencyImg:'',
      language:"en",
      loader:false
  },
  reducers: {
    sessionId: (state,action) => {
        // console.log("mjjj",state)
        state.sessionId = action.payload
    },
    getMenudta: (state,action) => {
        state.menudata = action.payload
    },
    banner:  (state,action) => {
        state.bannerData = action.payload
        state.banners = action.payload.banner[0]
    },
    currencyData : (state,action) => {
        state.currencyData = action.payload
    },
    currencyIcon: (state,action) => {
        state.currencyIcon = action.payload
    },
    currencyImg: (state,action) => {
        state.currencyImg = action.payload
    },
    currencyCode: (state,action) => {
        state.currencyCode = action.payload
    },
    language: (state,action) => {
        state.language = action.payload
    },
    loader: (state,action) => {
        state.loader = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { sessionId,getMenudta,banner,currencyData,currencyIcon,currencyImg,currencyCode,language,loader } = homeSlice.actions

export default homeSlice.reducer