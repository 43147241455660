import React, { useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import newsletters from '../Assets/Images/newsletter.png';
import { postData } from '../Helpers/request';
import { loader } from '../Reducer/homeReducer';
import swal from 'sweetalert';

const Newsletter = () => {
    // const history = useHistory();
  const [newsletteremail, setNewsletteremail] = useState("")
  const dispatch = useDispatch()
  const newsletter = async (e) => {
    e.preventDefault();
    dispatch(loader(true))
    const data = { 
      email: newsletteremail
    }
   const res = await postData('/common/subscribe_newsletter', data)
     console.log(res.data.status)
        if (res.data.status == 1) {
            dispatch(loader(false))
             setNewsletteremail('');
          return swal(res.data.message, "", "success");
        } else {
            dispatch(loader(false))
          return swal(res.data.message, "", "error");
        }
      
  }
    return (
        <>
            <div className='rt_newsletter_section'>
                <Container className='rt_custome_container'>
                    <Row className='m-auto w-100'>
                        <Col className='p-0'>
                            <div className='rt_newsletter_content'>
                                <h2>SIGN UP TO BE A Royal Trading INSIDER</h2>
                                <p>Become a preferred subscriber and get a special offer on your purchase.</p>
                                <div className="rt_news_form position-relative">
                                    <Form onSubmit={newsletter}>
                                        <Form.Group controlId="formBasicEmail">
                                            <Form.Control type="email" placeholder="email@newsletter.com" value={newsletteremail} onChange={(e) => setNewsletteremail(e.target.value)}  required/>
                                        </Form.Group>
                                        <Button type='submit'><Image src={newsletters} alt="News Btn Icon"></Image></Button>
                                    </Form>
                                </div>    
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>        
        </>
    );
}

export default Newsletter;
