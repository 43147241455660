import { Skeleton } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { isTablet } from "react-device-detect";
import { AiFillHeart } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { postData } from "../Helpers/request";
import { category } from "../Reducer/productReducers";

const ProductCategory = () => {
  const { slug } = useParams();
  const user = useSelector((state) => state.persistedReducer.user?.userData);
  const categorylists = useSelector(
    (state) => state.persistedReducerSession.product?.category
  );
  const sessionid = useSelector(
    (state) => state.persistedReducer.home?.sessionId
  );
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  useEffect(() => {
    getCategory();
  }, [slug]);
  const getCategory = async () => {
    setLoading(true)
    const data = {
      user_id: user?.user_id ? user?.user_id : "",
      session_id: sessionid ? sessionid : "",
      token: user?.token ? user?.token : "",
      category: slug,
    };
    const response = await postData("/common/category", data);
    if (response.data.status == 1) {
      dispatch(category(response.data?.data?.category));
      setLoading(false)
    }
  };
  return (
    <>
      <div className="rt_productlist_section_bg">
        <Container className="rt_custome_container">
          <Row className="w-100 m-auto">
            <Col>
              <div className="rt_productlist_banner_content">
                <h1>{slug}</h1>
                <ul>
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <BsArrowRight />
                  </li>
                  <li>
                    <NavLink to="#">{slug}</NavLink>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <br />
      <div className="rt_product_list">
        <Container className="rt_custome_container">
          <Row className="w-100 m-auto">
          {loading ? [...Array(16)].map(val=> <Col lg={3} md={4} sm={6} xs={12} >{isTablet ? <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', marginBottom: "15px"}} variant="text" animation="wave"  height={280} /> : <Skeleton sx={{ bgcolor: '#e4e4e4' }} style={{ transform: 'unset', marginBottom: "15px"}} variant="text" animation="wave"  height={350} />}</Col>):

            categorylists?.length ? (
              categorylists?.map((res, index) => (
                <Col lg={3} md={4} sm={6} xs={12} key={res.id}>
                  <div className="rt_product_list_content">
                    <div className="rt_product_list_content_top position-relative">
                      <NavLink to={"/" + res.slug}>
                        <Image
                          src={res.image}
                          alt="Royal Trading Product"
                        ></Image>
                      </NavLink>
                    </div>
                    <div className="rt_product_list_heading">
                      <NavLink to={"/" + res.slug}>
                        <h2>{res.name}</h2>
                      </NavLink>
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <div>No data Available</div>
            )}
          </Row>
          {/* <Row className="m-auto w-100">
              <div className="text-center rt_pagination">
                <Stack spacing={2}>
                  <Pagination count={10} />
                </Stack>
              </div>
            </Row> */}
        </Container>
      </div>
    </>
  );
};

export default ProductCategory;
