import axios from "axios";
import {
  useSelector
} from "react-redux";
import {
  Navigate,
  Outlet,
  useNavigate
} from "react-router";
import { cartCountvalue } from "../Reducer/cartReducer";
import { loader, sessionId } from "../Reducer/homeReducer";
import { userData } from "../Reducer/userReducers";
import {
  store
} from "../Store/store";

export const base_url = "https://royalchicago.com/webapi/api";
export const user = () => store.getState().persistedReducer.user?.userData ? store.getState().persistedReducer.user?.userData : [];
export const home = () => store.getState().persistedReducer.home ? store.getState().persistedReducer.home : [];

export const postHeader = {
  // 'authorization':`Token e222085edc871352345625625220cae528152b73c1317ba2addf`,
  'Accept': 'application/json',
  'Content-Type': 'application/json'
}

export const onTop = () => {
  window.scrollTo(0, 0);
}

function sessionValidation(response) {
  if (response.data.status == 2) {
    store.dispatch(loader(false))
    store.dispatch(userData([]))
    //store.dispatch(whishlistlength(0))
    store.dispatch(cartCountvalue(0))
    store.dispatch(sessionId(''))
    window.location.push("/")
    return false;
  };
  return true;
}

export async function postData(url, data) {
 
  if (data === null || data === undefined)
    data = new Object();
  data.language = home().language;
  data.currency = home().currencyCode;
  const response = await axios.post(base_url + url, data, {
    headers: postHeader,
  });
 
  if (!sessionValidation(response)) {
    return;
  };
  return response;
}


export async function getData(url) {

  if (url.indexOf('?') === -1)
    url = `${url}?language=${home().language}&currency=${home().currencyCode}`;
  else
    url = `${url}&language=${home().language}&currency=${home().currencyCode}`;

  const response = await axios.get(base_url + url, {
    headers: postHeader
  });

  if (!sessionValidation(response)) {
    return;
  };
  return response;
}

