import React, { useEffect } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import '../Assets/Css/Home.css';
import { getData, postData } from '../Helpers/request';
import { banner, sessionId } from '../Reducer/homeReducer';
import AreaRugs from './AreaRugs';
import BannerSlider from './BannerSlider';
import BarStools from './BarStools';
import Categories from './Categories';
import RugsSize from './RugsSize';

const Home = () => {
    const dispatch = useDispatch()   
    const session = useSelector(state => state.persistedReducer.home?.sessionId)
    const user = useSelector(state => state.persistedReducer.user?.userData)
    console.log(session)
    useEffect(()=>{
        getBannerdata()
        getSessionid()
    },[])
    const getSessionid = async () =>{
        if (session != "" || user.isLogin) {
            console.log("session exist!")
          } else {
            const res = await getData('/common/getsessionid')
                if (res.data.status == 1) {
                  dispatch(sessionId(res.data.data?.session_id))
                } else {
                  console.log("sessionid not generated")
                }
              }
            }
    
    const getBannerdata = async () => {
      const data = {
      session_id: session ? session : "",
      token: user?.token ? user?.token : "",
      user_id: user?.user_id ? user?.user_id : ""
    }
        const res = await postData('/common/home',data)
        if(res.data?.status == 1){
            dispatch(banner(res.data?.data))
        }
    }
    return (
        <>
            <BannerSlider/>
            <Categories/>
            <RugsSize/>
            <AreaRugs/>
            <BarStools/>            
        </>
    );
}

export default Home;