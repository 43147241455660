import { createSlice } from "@reduxjs/toolkit";
export const wishSlice = createSlice({
  name: "wish",
  initialState: {
    wishlistData: [],
    whishlistlength: "",
  },
  reducers: {
    wishlistData: (state, action) => {
      state.wishlistData = action.payload;
      state.whishlistlength = action.payload.wishlist_data == [] ? "" : state.whishlistlength;
    },
    whishlistlength: (state, action) => {
      state.whishlistlength = action.payload;
    },
  },
});

export const { wishlistData, whishlistlength } = wishSlice.actions;
export default wishSlice.reducer;
