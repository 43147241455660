import React, { useState } from 'react'
import { useEffect } from 'react';
import { Col, Image } from 'react-bootstrap'
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom'
import { postData } from '../Helpers/request';
import { cartCountvalue } from '../Reducer/cartReducer';
import { cartListcount, productData } from '../Reducer/productReducers';
import { whishlistlength, wishlistData } from '../Reducer/wishlistReducer';
import { toast } from 'react-toastify';

const ProductListsComp = (props) => {

  const navigate = useNavigate();
    const dispatch = useDispatch();
    const {data}= props;
    const user = useSelector((state) => state.persistedReducer.user?.userData);
  const [isLogin, setIsLogin] = useState(user.isLogin);

  useEffect(() => {
    setIsLogin(user.isLogin);
  }, [user])

  const productlists = useSelector(
    (state) => state.persistedReducerSession.product?.productData
  );
  const sessionid = useSelector(
    (state) => state.persistedReducer.home?.sessionId
  );
  const [qty, setQty] = useState(Number(data.cart_quantity));
// useEffect(() => {
// if (qty>data?.available_stock) {
//   setQty(qty - Number(data.minimum_qty))
// }
// }, [qty])

  const increment = () => {
    if(isLogin){
      const ishave = qty + Number(data.minimum_qty)>data?.available_stock
      if (!ishave) {
        setQty(qty + Number(data.minimum_qty));
        addtocart(data?.product_id,qty + Number(data.minimum_qty));
      }
  }else{
     navigate('/login')
  }
}

  const decrement = () => {
    if(isLogin){
      if (qty > Number(data.minimum_qty)) {
      setQty(qty - Number(data.minimum_qty));
      addtocart(data?.product_id,qty - Number(data.minimum_qty));
      }else{
        setQty(0);
        addtocart(data?.product_id,qty = 0);
      }
      
    }else{
       navigate('/login')
    }
  }

  const cartlistcount = useSelector(
    (state) => state.persistedReducer.cart?.cartcount
  );
  console.log('cartlistcount',cartlistcount);
  const addtocart = async (product_id,quantity) => {
    const data = {
      user_id: user.user_id ? user.user_id : "",
      session_id: sessionid ? sessionid : "",
      product_id,
      quantity: quantity,
      ring_size: "",
      engraving_text: "",
      engraving_font: "",
      token: user ? user.token : "",
      type: "",
    };
    const res = await postData("/order/add_to_cart", data);

    if (res.data.status == 1) {
      dispatch(cartCountvalue(res.data.data.total_count));
      if (!isLogin) {
        navigate('/login')
      }
     
    } else {
      toast.error(res.data.message, { autoClose: 3000 });
    }
  };


  const addtowishlist = async (val, wishindex) => {
    const data = {
      user_id: user.user_id ? user.user_id : "",
      session_id: sessionid ? sessionid : "",
      product_id: val.product_id,
      type: "",
      token: user ? user.token : "",
    };
    const res = await postData("/order/add_to_wishlist", data);

    if (res.data?.status == 1) {
      dispatch(whishlistlength(res.data.data.total_count));
      dispatch(wishlistData(res.data.data));
      var product = productlists?.result?.map(
        (obj, index) => (index == wishindex ? { ...obj, is_wishlist: res.data.data.wishlist_id } : obj)
      )
      dispatch(productData({ ...productlists, result: product }))
    }
  };

  return (
    <Col xl={3} lg={4} md={4} sm={6} xs={12} key={data?.product_id}>
    <div className="rt_product_list_content">
      <div className="rt_product_list_content_top position-relative">
        <div className='rt_nunito-font'>
        <NavLink to={`/product-detail/${data?.slug}`}>  <Image
            src={data?.product_image[0].url}
            alt="Royal Trading Product"
            className="rt_product_image_hover"
          ></Image>
          </NavLink>
        </div>
        <div className="rt_product_list_heading">
          <NavLink to={`/product-detail/${data?.slug}`}>
            <h2>{data?.name}</h2>
          </NavLink>
          <h5>SKU - {data?.sku}</h5>
          <h5>AVAILABLE QUANTITY - {data?.available_stock}</h5>
        </div>
      </div>
      <div className="rt_wishlist_icon">
        <span>
          {data?.is_wishlist == "0" ? (
            <AiOutlineHeart
              onClick={() => addtowishlist(data, props.index)}
            />
          ) : (
            <AiFillHeart
              style={{ color: "#F3759E" }}
              onClick={() => addtowishlist(data, props.index)}
            />
          )}
        </span>
      </div>
      <div className="rt_product_list_heading">
       
        {/* <p>$75 <del>$100</del></p> */}
        <div className='rt_price_pera_div'>
       
         <div className='rt_incri_decriment'>
                  <div className="input-group rt_quantity1 mt-2">
                  <div className="input-group-prepend">
                    <button
                    onClick={decrement}
                      className="btn btn-outline-primary"
                      type="button"
                    >
                      -
                    </button>
                  </div>
                  <input

                    type="text"
                    className="form-control rt_product_count_text"
                    value={qty}
                  />
                  <div className="input-group-prepend">
                      <button
                        onClick={qty < data?.available_stock && increment}
                        className="btn btn-outline-primary"
                      >
                        +
                      </button>
                  </div>
               </div>
        </div>
         <p>
          {" "}
          {user.isLogin
            ? data?.currency_icon + "" + data?.sale_price
            : ""}
        </p>
        </div>
        
      </div>
      {/* <div className='text-center'>
         <button className="rt_productlist_add_to_cart_button" onClick={()=>isLogin ? addtocart(data?.product_id) : navigate('/login')}>Add to Cart</button>
      </div> */}
    </div>
  </Col>
  )
}

export default ProductListsComp
